import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import axios from '../../utils/axios';
import { useUser } from '../../contexts/auth';
import "./PathologyReport.css";
import PrintComponent from './PrintComponent';
import Headerimg from "../../assets/images/Headerimg.png";
import InvoicePrintComponent from './InvoicePrintComponent';
const InvoicePrint = (props) => {
    const { userid, userrole, companycode, token } = useUser();
    const [invoiceData, setInvoiceData] = useState([]);
    const [Data, setData] = useState({});

    const fetchData = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const response = await axios.get(`/invoice/${props.sId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                if (response && response.data) {
                    setInvoiceData(response.data);
                    setData(response.data[0]);
                }
            }
        } catch (error) {
            // Handle error
        }
    };

    useEffect(() => {
        fetchData();
    }, [companycode, userrole]);

    const commonContent = (
        <div>
            <div className="invoice-header">
                <div className="company-details">
                    <h4>Invoice</h4>
                </div>
                <div className="invoice-details">
                    <img src={Headerimg} alt="Header" style={{maxWidth: '100px', height: 'auto'}} />
                </div>
            </div>
            <div className="invoice-header">
                <div className="company-details">
                    <p>BBS Tech Solution</p>
                    <p>No-20,1st Floor,Thirumenahalli Main Rd</p>
                    <p>Hegde Nagar, Bengaluru - 560064</p>
                    <p>Phone: 8035852916</p>
                    <p>GSTIN: 29AXPGP9169A1Z3</p>
                    <p>A/C No : 10996500761</p>
                    <p>IFSC Code : IDFB0080177</p>
                </div>
                <div className="invoice-details">
                    <p>DATE: {Data.currentdate}</p>
                </div>
            </div>
            <div className="bill-to">
                <p>BILL TO:</p>
                <p>{Data.name}</p>
                <p>{Data.address}</p>
            </div>
        </div>
    );

    return (
        <div>
            <InvoicePrintComponent commonContent={commonContent}>
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceData.map((item, index) => (
                            <tr key={index}>
                                 <td>{item.activityname}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.totalcost}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3">SUBTOTAL</td>
                            <td>{Data.subTotal}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Discount %</td>
                            <td>{Data.discount}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Discount Amount</td>
                            <td>{Data.discountedamount}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">SGST 9.00%</td>
                            <td>{Data.sgst}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">CGST 9.00%</td>
                            <td>{Data.cgst}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">TOTAL TAX</td>
                            <td>{Data.totalGst}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">TOTAL (Rounded Off)</td>
                            <td>{Data.totalAmount}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Advance Payment</td>
                            <td>{Data.advancepayment}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">Due Payment</td>
                            <td>{Data.duepayment}</td>
                        </tr>
                    </tfoot>
                </table>
            </InvoicePrintComponent>
        </div>
    );
};

export default InvoicePrint;