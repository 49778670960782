import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import { useParams } from "react-router-dom";
import Header from "../../assets/charts/Header";
import {
  TextField,
  Button,
  Box,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Autocomplete
} from "@mui/material";

const EditHoliday = () => {
  const { token, username } = useUser();
  const { holidayid } = useParams();

  const [holidayEdit, setHolidayEdit] = useState({
    holidayname: "",
    holidaydate: "",
    entrydate: "",
  });

  const [userRole, setUserRole] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    editUser();
  }, []);

  const editUser = async () => {
    try {
      const res = await axios.get(`/holidaydetails/${holidayid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newHoliday = res.data;
      setHolidayEdit(newHoliday[0]);
    } catch (err) {
      console.error("Failed to fetch holiday details", err);
    }
  };

  const handleEdit = (e) => {
    setHolidayEdit({ ...holidayEdit, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend =  holidayEdit;
      const response = await axios.post(
        `/holidayupdate/${holidayid}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Role: userRole,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setError(false);
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }

    setHolidayEdit({
      holidayname: "",
      holidaydate: "",
      entrydate: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>Edit Holiday</title>
      </Helmet>
      <div>
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-success">
              Form submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting holiday details. Please try again later.
            </div>
          )}
           <Box m="20px">
        <Header title="Modify Holiday" />
        <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  type="text"
                   label="Holiday Name"
                  name="holidayname"
                  id="holidayname"
                  placeholder="Holiday Name"
                  value={holidayEdit.holidayname}
                  required
                  onChange={handleEdit}
                  fullWidth
                  margin="normal"
                />
                {errors.holidayname && touched.holidayname && (
                  <p className="form-error">{errors.holidayname}</p>
                )}
        
                <TextField
                  type="date"
                   label="Holiday Date"
                  name="holidaydate"
                  id="holidaydate"
                  placeholder="Holiday Date"
                  value={holidayEdit.holidaydate}
                  required
                  onChange={handleEdit}
                  fullWidth
                  margin="normal"
                />
                {errors.holidaydate && touched.holidaydate && (
                  <p className="form-error">{errors.holidaydate}</p>
                )}
          </Box>
                <TextField
                  type="date"
                  label="Entry Date"
                  name="entrydate"
                  id="entrydate"
                  placeholder="Entry Date"
                  value={holidayEdit.entrydate}
                  required
                  onChange={handleEdit}
                  fullWidth
                  margin="normal"
                />
                {errors.entrydate && touched.entrydate && (
                  <p className="form-error">{errors.entrydate}</p>
                )}

          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit">
              Update
            </Button>
            <Button variant="contained" color="secondary" type="reset" sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
          </Box>
        </form>
      </div>
    </>
  );
};

export default EditHoliday;

  