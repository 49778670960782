import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Logo from "../../asset/logo2.png";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./style.css";
import "./style.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LinkedIn, Instagram, Twitter, Facebook } from "@mui/icons-material";
import { Helmet } from "react-helmet";
// import BgImage from "../../Assets/img/landingimage.png";
// import BgImage from "../../asset/bgimage.png";

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  return (
    <>
      <Helmet>
        <title> Home</title>
      </Helmet>
      <div className="landing-page">
        <nav className="navbar navbar-expand-lg  px-0 py-3">
          <div className="container-xl">
            <Link to="/login" className="navbar-brand">
              {/* <img
                src={Logo}
                className="h-8"
                alt="Logo"
                style={{ height: "3rem", width: "100%" }}
              /> */}
            </Link>
            {/* Navbar toggle */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded={!isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Collapse */}
            <div
              className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
              id="navbarCollapse"
            >
              {/* Nav */}
              <div className="navbar-nav mx-lg-auto">
                <Link
                  to="/"
                  className="nav-item nav-link active"
                  aria-current="page"
                >
                  Home
                </Link>
                <Link to="" className="nav-item nav-link">
                  Services
                </Link>
                <Link to="" className="nav-item nav-link">
                  Features
                </Link>
                <Link to="" className="nav-item nav-link">
                  Client
                </Link>
                <Link to="" className="nav-item nav-link">
                  Price
                </Link>
              </div>
              {/* Right navigation */}
              <div className="navbar-nav ms-lg-4">
                <Link to="/login" className="nav-item nav-link">
                  Login
                </Link>
              </div>
              {/* Action */}
              <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
                <Link
                  to=""
                  className="btn btn-sm  w-full w-lg-auto landing-page-navbar-button"
                >
                  Get Started{" "}
                  <span>
                    <ChevronRightIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Two columns using Bootstrap grid */}
        <div className="container-fluid body-part">
          <div className="row">
            <div className="col-lg-6">
              {/* First column */}
              <div className="landing-page-first-column">
                {/* <div className="row">
                  <div className="col-sm-12 text-white mt-1">
                    <h1>
                      Where Relationships Flourish Unleadshing{" "}
                      <span style={{ color: "#DF6D33" }}>the Power of CRM</span>
                    </h1>
                  </div>
                  <div className="col-sm-12 text-white mt-3">
                    <p>
                      Empower your buisness with seamless customer connections
                      through our intuitive CRM platform
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-6">
                          <Link to="login">
                            <button
                              type="btn"
                              className="button landing-page-login-btn"
                            >
                              Log in{" "}
                              <span>
                                <ArrowRightAltIcon />
                              </span>
                            </button>
                          </Link>
                        </div>
                        <div className="col-lg-8 col-ms-8 col-6 text-white pt-2">
                          or{" "}
                          <Link to="" className="start-free-traial">
                            Start Free Trial
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <LinkedIn
                      style={{
                        backgroundColor: "#0e76a8",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "white",
                        fontSize: 35,
                        marginRight: "20px",
                      }}
                    />
                    <Instagram
                      style={{
                        backgroundColor: "#bc2a8d",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "white",
                        fontSize: 35,
                        marginRight: "20px",
                      }}
                    />
                    <Twitter
                      style={{
                        backgroundColor: "#1da1f2",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "white",
                        fontSize: 35,
                        marginRight: "20px",
                      }}
                    />
                    <Facebook
                      style={{
                        backgroundColor: "#1877f2",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "white",
                        fontSize: 35,
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              {/* Second column */}
              <div className="landing-page-second-column">
                {/* <img
                  src={BgImage}
                  alt="Landing Page Background"
                  style={{ height: "25rem", width: "100%", objectFit: "fill" }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;