import React, { useState, useEffect } from "react";
import Header from "../../assets/charts/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../utils/axios";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/auth";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from 'yup';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Box,
    FormControl,
    Autocomplete,
    MenuItem,
    InputLabel,
    Select,
    Snackbar,
} from "@mui/material";
import CompanycodeSearch from "../../common/CompanycodeSearch";
import { useParams } from "react-router-dom";

const InvoiceModify = () => {
    const { userid, userrole, companycode, token } = useUser();
    const { invoiceno } = useParams();
    const initialValues = {
        companyCodes: "",
        companycode: "",
        projectcode: "",
        projectname: "",
        address:"",
    };
    const padNumber = (number, length) => {
        let str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    };
    const userTemp = {
        price: "",
        quantity: "",
        totalcost: ""
    };
    const [searchValues, setSearchValues] = useState("");
    const [goodsInventory, setGoodsInventory] = useState([userTemp]);
    const [allemployeelist, setallemployeelist] = useState([]);
    const [cgst, setCgst] = useState(9);
    const [sgst, setSgst] = useState(9);
    const [totalGst, setTotalGst] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const[discount,setDiscount]= useState(0);
    const [discountedTotal, setDiscountTotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const[advancepayment,setAdvancepayment]=useState(0);
    const[duepayment,setDuepayment]=useState(0);

    const addItemrow = () => {
        setGoodsInventory([...goodsInventory, userTemp]);
    };

    const deleteRow = (index) => {
        const updatedUsers = [...goodsInventory];
        updatedUsers.splice(index, 1);
        setGoodsInventory(updatedUsers);
    };

    const Showdate = new Date();
    const ShowTodaydate = `${Showdate.getFullYear()}-${Showdate.getMonth() + 1}-${Showdate.getDate()}`;
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [selectedCustomer, setSelectedCustomer] =  useState(null);
    const [client, setClient] = useState([]);
    const validationSchema = Yup.object({

    });

    const onChange = (e, index) => {
        const { name, value } = e.target;
        const updatedUser = [...goodsInventory];
        updatedUser[index] = { ...updatedUser[index], [name]: value };

        if (name === "price" || name === "quantity") {
            const price = parseFloat(updatedUser[index].price) || 0;
            const quantity = parseFloat(updatedUser[index].quantity) || 0;
            updatedUser[index].totalcost = (price * quantity).toFixed(2);
        }

        setGoodsInventory(updatedUser);
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setValues
    } = useFormik({
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("currentDate", JSON.stringify(currentDate));
                data.append("addInventory", JSON.stringify(goodsInventory));
                data.append("companycode", padNumber(companycode, 5));
                data.append("subTotal", JSON.stringify(subTotal));
                data.append("totalAmount", JSON.stringify(totalAmount));
                data.append("cgst", JSON.stringify(cgst));
                data.append("sgst", JSON.stringify(sgst));
                data.append("discount", JSON.stringify(discount));
                data.append("discountedTotal", JSON.stringify(discountedTotal));
                data.append("totalGst", JSON.stringify(totalGst));
                data.append("advancepayment", JSON.stringify(advancepayment));
                data.append("duepayment", JSON.stringify(duepayment));
                data.append("userid", userid)
                if (selectedCustomer) {
                    data.append("client", selectedCustomer.customerid); // Send selected customer ID
                  }
                console.log(JSON.stringify(values))
                console.log(selectedCustomer.customerid)
                // console.log(JSON.stringify(currentDate))
                // console.log(JSON.stringify(goodsInventory))
                // console.log(padNumber(companycode, 5))
                const response = await axios.post(`/updateinvoice/${invoiceno}`, data, {
                    "Content-Type": "multipart/form-data",
                });

                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                }
            } catch (err) {
                setSuccess(false);
                setError(true);
            }
            action.resetForm();
        },
    });
    const [activity, setActivity] = useState([]);
    const [project, setProject] = useState([]);
    const fetchData = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const response = await axios.get(`/invoice/${invoiceno}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                console.log(response.data);
                if (response && response.data && response.data.length > 0) {
                    setGoodsInventory(response.data);
                    setValues(response.data[0]);
                    setDiscount(response.data[0].discount);
                    setDiscountTotal(response.data[0].discountedamount)
                    setCgst(response.data[0].cgst)
                    setSgst(response.data[0].sgst)
                    setSubTotal(response.data[0].subTotal)
                    setTotalAmount(response.data[0].totalAmount)
                    setAdvancepayment(response.data[0].advancepayment)
                     setDuepayment(response.data[0].duepayment)
                    
                    setSelectedCustomer({
                        customerid: response.data[0].customerid,
                        name: response.data[0].name
                      })
                }
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
        }
    };
    
    const loadActivity = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const res = await axios.get('/getactivity', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                setActivity(res.data);
            }
        } catch (error) {
            console.error('Error loading activities:', error);
        }
    };
    const LoadClient = async () => {
        try {
            const res = await axios.get("/customerget");
            const customers = res.data.map(customer => ({
                customerid: customer.customerid,
                name: customer.name,
                address: `${customer.companyname},${customer.address},${customer.state}, ${customer.zipcode}`,
            }));
            setClient(customers);
            console.log(res.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const loadProject = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const res = await axios.get('/getproject', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                console.log(res.data)
                setProject(res.data);
            }
        } catch (error) {
            console.error('Error loading activities:', error);
        }
    };
    useEffect(() => {
        fetchData();
        loadProject();
        loadActivity();
        LoadClient();
    }, [companycode, userrole]);
    useEffect(() => {
        //subtotal
        const newSubTotal = goodsInventory.reduce((acc, item) => acc + parseFloat(item.totalcost || 0), 0);
        //discouted subtotal
        const discountedTotal = newSubTotal * (1 - discount / 100);
        //discout amount
        const discountamount = newSubTotal-discountedTotal;
        //total gst
        const newTotalGst = (discountedTotal * cgst / 100) + (discountedTotal * sgst / 100);
        //total amout
        const newTotalAmount = discountedTotal + newTotalGst;
        const newDuepayment = newTotalAmount - advancepayment
        setDiscountTotal(discountamount.toFixed(2))
        setSubTotal(newSubTotal.toFixed(2));
        setTotalGst(newTotalGst.toFixed(2));
        setTotalAmount(newTotalAmount.toFixed(2));
        setDuepayment(newDuepayment.toFixed(2))
    }, [goodsInventory, discount, cgst, sgst,advancepayment]);
   
    const renderLabel = (label, isRequired) => (
        <span>
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </span>
    );

    const onSearchItems = (searchTerm) => {
        console.log("------", searchTerm);
        setSearchValues(searchTerm);
        setValues({
            ...values,
            companyCodes: searchTerm.companycode,
        });
    };
    const handleCustomerChange = (event, value) => {
        setSelectedCustomer(value);
        if (value) {
            // Assuming the customer has an address field
            values.address = value.address;
        } else {
            values.address = "";
        }
    };
    return (
        <>

            <form onSubmit={handleSubmit}>
                {success && (
                    <Snackbar
                        open={success}
                        autoHideDuration={30000} // 60 seconds     
                        message="Form submitted successfully!"
                    />
                )}
                {error && (
                    <Snackbar
                        open={error}
                        autoHideDuration={3000} // 60 seconds
                        message="Error submitting form. Please try again later."
                    />
                )}
                <Box m="20px">
                    <Header title="Invoice Revision" />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {/* {userrole === "Superadmin" && companycode === 1 ? (
                            <FormControl
                                fullWidth
                                variant="filled"
                                sx={{ gridColumn: "span 2" }}
                            >
                                <CompanycodeSearch onSearchItem={onSearchItems} />
                            </FormControl>
                        ) : ( */}
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Company Code"
                            name="companyCodes"
                            id="companyCodes"
                            value={padNumber(values.companycode, 5)}
                            sx={{ gridColumn: "span 2" }}
                        />
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={project}
                            getOptionLabel={(option) => option.projectname}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Project Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="projectname"
                                    error={!!touched.projectname && !!errors.projectname}
                                    helperText={touched.projectname && errors.projectname}
                                />
                            )}
                            value={project.find((proj) => proj.projectname === values.projectname) || null}
                            onChange={(event, newValue) => {
                                handleChange({
                                    target: {
                                        name: 'projectname',
                                        value: newValue?.projectname || ''
                                    }
                                });
                                setValues({
                                    ...values,
                                    projectcode: newValue?.projectcode || '',
                                    projectname: newValue?.projectname || ''
                                });
                            }}
                            sx={{ gridColumn: "span 4" }}
                        /> */}
                       <TextField
                            fullWidth
                            label={renderLabel("Quotation Heading", true)}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            name="projectcode"
                            id="projectcode"
                            multiline
                            value={values.projectcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.projectcode && !!errors.projectcode}
                            helperText={touched.projectcode && errors.projectcode}
                        />
                    </Box>
                    <br/>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Autocomplete
                            fullWidth
                            variant="filled"
                            disablePortal
                            id="search"
                            options={client}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.customerid}
                            sx={{ gridColumn: "span 2" }}
                            renderInput={(params) => <TextField {...params} label="Customer Name" />}
                            onChange={handleCustomerChange}
                            value={selectedCustomer}
                        />
                        
                        <TextField
                            fullWidth
                            label={renderLabel("Delivery Address", true)}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            name="address"
                            id="address"
                            multiline
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                        />
                    </Box>
                    <Box mt="20px" style={{ display: "flex", flexDirection: "column" }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl No</TableCell>
                                        <TableCell  style={{ width: "220px" }}>Activity</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>UnitPrice</TableCell>
                                        <TableCell>TotalCost</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {goodsInventory.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <Autocomplete
                                                    fullWidth
                                                    variant="filled"
                                                    disablePortal
                                                    id="activity-autocomplete"
                                                    options={activity}
                                                    value={activity.find(act => act.activitycode === user.activitycode) || null}
                                                    getOptionLabel={(option) => option.activityname || ""}
                                                    onChange={(e, newValue) => {
                                                        const updatedUser = [...goodsInventory];
                                                        updatedUser[index] = {
                                                            ...updatedUser[index],
                                                            activitycode: newValue?.activitycode || ""
                                                        };
                                                        setGoodsInventory(updatedUser);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Activity"
                                                            name="activity"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                          
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="quantity"
                                                    onChange={(e) => onChange(e, index)}
                                                    value={user.quantity}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="price"
                                                    onChange={(e) => onChange(e, index)}
                                                    value={user.price}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="totalcost"
                                                    value={user.totalcost}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => deleteRow(index)}
                                                    style={{ color: "white", backgroundColor: "red" }}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <br />
                    <Button color="secondary"
                        variant="contained" onClick={addItemrow}>{<AddIcon />} <b>Add Row</b></Button>
                    <br />
                    <br />
                    <div>
                        <TextField
                            label="Sub Total"
                            id="SubTotal"
                            variant="outlined"
                            value={subTotal}
                            InputProps={{ readOnly: true }}
                        />
                       <TextField
                            label="Discount%"
                            id="discount"
                            variant="outlined"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                        />
                         <TextField
                            label="Discount Amount"
                            id="discountamount"
                            variant="outlined"
                            value={discountedTotal}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="CGST%"
                            id="cgst"
                            name="cgst"
                            value={cgst}
                            onChange={(e) => setCgst(e.target.value)}
                        />
                         <TextField
                            label="SGST%"
                            id="sgst"
                            name="sgst"
                            value={sgst}
                            onChange={(e) => setSgst(e.target.value)}
                        />
                        </div>
                        <br/>
                        <div>
                       
                        <TextField
                            label="Total GST"
                            id="TotalGst"
                            variant="outlined"
                            value={totalGst}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Total Amount"
                            id="TotalAmount"
                            variant="outlined"
                            value={totalAmount}
                            InputProps={{ readOnly: true }}
                        />
                    <TextField
                            label="Advance Payment"
                            id="advancepayment"
                            variant="outlined"
                            value={advancepayment}
                             onChange={(e) => setAdvancepayment(e.target.value)}
                        />
                    <TextField
                            label="Due Payment"
                            id="duepayment"
                            variant="outlined"
                            value={duepayment}
                            InputProps={{ readOnly: true }}
                        />
                    </div>
                    <Box display="flex" justifyContent="initial" mt="20px">
                        {/* <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                id="draft"
                                onClick={() => {
                                    handleChange({
                                        target: { name: "orderstage", value: "Draft" },
                                    });
                                }}
                            >
                                Save As Draft
                            </Button>
                        </Box> */}
                        <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Box>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>
                    </Box>
                    <br />
                </Box>
            </form>
        </>
    );
};

export default InvoiceModify;