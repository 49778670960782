import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../../utils/axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {  useUser } from "../../../contexts/auth";
import { SHA256 } from "crypto-js";
const Adduser = () => {
  const {
    userid,
    userrole,
    username,
    companycode,
  } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    phoneno: "",
    address: "",
    role: "",
    password:"",
    conformpassword:"",
    experience:0,
    jobtype:"",
    department:"",
    paidleave:"",
    companyCodes: userrole === "Superadmin" && companycode === 1 ? "" : companycode,
  };
// hashing password
const hashPassword = (password) => {
  return SHA256(password).toString();
};
  const ValidationSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    role: yup.string().required("Role is required"),
    companyCodes: yup.string().required("Company Code is required"),
    email: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
    phoneno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    password: yup.string().min(6).required("Please enter the password"),
    conformpassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        setData(response.data);
        setFilteredData(response.data);
        const formattedCodes = response.data.map((company) => company.companycode);
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const selectDate = (sd) => {
    setBirthDate(sd);
  };

  const selectJoiningDate = (sd) => {
    setJoiningDate(sd);
  };

  const header = {
    headers: {
      Authorization: `Bearer EAAMVy5OZB2W4BO3xMiEuhNtYZBY4FqGfk9GGDOXTZCSAmj3V4kr12mUdM0aWTrzPZBYyD6F5dXhifyigaLFm5YTETOcSlgKm8hdF6bRh3F51qNLZAL7D91HGkKtvh6O2biCBbqyX2qrcsdPm9ELHltqNsZADOOFvDwJXYZBABR6i5FSuRZBRf8XZAZB9ZBRoqhnN4GZB`,
      Accept: `application/json`
    }
  };

  const sendMsg = (values) => {
    const body = {
      "messaging_product": "whatsapp",
      "to": "91" + values.phoneno,
      "type": "template",
      "template": {
        "name": "notification",
        "language": {
          "code": "en"
        }
      }
    };
    axios.post('https://graph.facebook.com/v18.0/217459234774748/messages', body, header)
      .then((res) => (
        console.log("msg sent success", res)
      ))
      .catch((err) => (
        console.log(err)
      ));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        try {
          if (userrole !== "Superadmin" || companycode !== 1) {
            values.companyCodes = companycode;
          }
          const hashedPassword = hashPassword(values.password);
          values.password = hashedPassword;
          const formDataToSend = {
            ...values,
            username,
            userrole,
            companycode,
            dob: formatDate(birthDate),
            joiningdate: formatDate(joiningDate),
          };
         
          const response = await axios.post(
            "/userRegistration",
            formDataToSend,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 201) {
            setSuccessMessage("Employee registration successful");
            sendMsg(values);
            setOpenSuccess(true);

            setTimeout(() => {
              setOpenSuccess(false);
              setSuccessMessage("");
            }, 3000);
          }
        } catch (error) {
          console.error("Error submitting form:", error);

          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
          }
        }
      }}
    >
      {(formikProps) => (
        <>
          <form onSubmit={formikProps.handleSubmit}>
            <Box m="20px">
              <h3> Add Employee</h3>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="First Name"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.firstname}
                  name="firstname"
                  error={
                    formikProps.touched.firstname &&
                    !!formikProps.errors.firstname
                  }
                  helperText={
                    formikProps.touched.firstname && formikProps.errors.firstname
                      ? "First name is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Last Name"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.lastname}
                  name="lastname"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Email"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.email}
                  name="email"
                  error={formikProps.touched.email && !!formikProps.errors.email}
                  helperText={
                    formikProps.touched.email && formikProps.errors.email
                      ? "Email is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Phone Number"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.phoneno}
                  name="phoneno"
                  error={
                    formikProps.touched.phoneno && !!formikProps.errors.phoneno
                  }
                  helperText={
                    formikProps.touched.phoneno && formikProps.errors.phoneno
                      ? "Phone number is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Address"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.address}
                  name="address"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Age"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.age}
                  name="age"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.password}
                  name="password"
                  error={
                    formikProps.touched.password &&
                    !!formikProps.errors.password
                  }
                  helperText={
                    formikProps.touched.password && formikProps.errors.password
                      ? "Password is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.conformpassword}
                  name="conformpassword"
                  error={
                    formikProps.touched.conformpassword &&
                    !!formikProps.errors.conformpassword
                  }
                  helperText={
                    formikProps.touched.conformpassword && formikProps.errors.conformpassword
                      ? "Passwords must match"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="dob"
                    label="Date Of Birth"
                    value={dayjs(birthDate)}
                    onChange={(e) => selectDate(e["$d"])}
                    sx={{ gridColumn: "span 2" }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="joiningdate"
                    label="Joining Date"
                    value={dayjs(joiningDate)}
                    onChange={(e) => selectJoiningDate(e["$d"])}
                    sx={{ gridColumn: "span 2" }}
                  />
                </LocalizationProvider>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2" }}
                >
                  <InputLabel id="role">Role</InputLabel>
                  <Select
                    label="Role"
                    id="role"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.role}
                    name="role"
                    error={formikProps.touched.role && !!formikProps.errors.role}
                  >
                    <MenuItem value="">Select Role</MenuItem>
                    <MenuItem value="Superadmin">Superadmin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="Employee">Employee</MenuItem>
                  </Select>
                </FormControl>

                {userrole === "Superadmin" && companycode === 1 ? (
                  <FormControl
                    fullWidth

                    sx={{ gridColumn: "span 2" }}
                  >
                    <InputLabel id="companyCode-label">Company Code</InputLabel>
                    <Select
                      labelId="companyCode-label"
                      id="companyCodes"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.companyCodes}
                      name="companyCodes"
                      error={
                        formikProps.touched.companyCodes &&
                        !!formikProps.errors.companyCodes
                      }
                      label="Company Code"
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    >
                      <MenuItem value="">Select Company Code</MenuItem>
                      {companyCodes.map((code, index) => (
                        <MenuItem
                          key={index}
                          value={code}
                        >
                          {code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    fullWidth

                    type="text"
                    label="Company Code"
                    value={companycode}
                    name="companyCodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                )}
                 <TextField
                  fullWidth
                  type="text"
                  label="Job Type"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.jobtype}
                  name="jobtype"
                  error={
                    formikProps.touched.jobtype &&
                    !!formikProps.errors.jobtype
                  }
                  helperText={
                    formikProps.touched.jobtype && formikProps.errors.jobtype
                      ? "First name is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Experience (in years)"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.experience}
                  name="experience"
                  sx={{ gridColumn: "span 2" }}
                />
                  <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2" }}
                >
             <InputLabel id="department">Department</InputLabel>
                  <Select
                    label="Department"
                    id="department"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.department}
                    name="department"
                    error={formikProps.touched.department && !!formikProps.errors.department}
                  >
                    <MenuItem value="">Select Department</MenuItem>
                    <MenuItem value="Business management">Business management</MenuItem>
                    <MenuItem value="Business Administration">Business Administration</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Purchase">Purchase</MenuItem>
                    <MenuItem value="Marketing">Marketing</MenuItem>
                    <MenuItem value="Technical">Technical</MenuItem>
                    <MenuItem value="Non Technical">Non Technical</MenuItem>
                    <MenuItem value="Store">Store</MenuItem>
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                   
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="number"
                  label="Paid Leave"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.paidleave}
                  name="paidleave"
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Box>
            </Box>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSuccess}
            autoHideDuration={3000}
            message={successMessage}
          />
        </>
      )}
    </Formik>
  );
};

export default Adduser;
