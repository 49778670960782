
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box, Snackbar } from "@mui/material";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import { styled } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
const ReviseQuotation = ({ data }) => {
  const { userid, companycode } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [goodsInventory, setGoodsInventory] = useState(data);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const padNumber = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  };

  const validationSchema = Yup.object({});

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues: { ...data[0] },
  });

  useEffect(() => {
  }, [goodsInventory]);

  const renderLabel = (label, isRequired) => (
    <span>
      {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
    </span>
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        {success && (
          <Snackbar
            open={success}
            autoHideDuration={30000} // 60 seconds
            message="Form submitted successfully!"
          />
        )}
        {error && (
          <Snackbar
            open={error}
            autoHideDuration={3000} // 60 seconds
            message="Error submitting form. Please try again later."
          />
        )}
        <Box m="20px">
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              // variant="filled"
              type="text"
              label="Company Code"
              name="companycode"
              id="companycode"
              value={padNumber(values.companycode, 5)}
              sx={{ gridColumn: "span 2" }}
             
            />
             <TextField
              fullWidth
              // variant="filled"
              type="text"
              label="Quotation Heading"
              name="projectcode"
              id="projectcode"
              value={values.projectcode}
              sx={{ gridColumn: "span 2" }}
             
            />
          </Box><br/>
          <Box sx={{ display: 'flex', gap: 2 }}>
<TextField
              fullWidth
              // variant="filled"
              type="text"
              label="Customer"
              name="name"
              id="name"
              value={values.name}
              sx={{ gridColumn: "span 2" }}
             
            />
             <TextField
              fullWidth
              // variant="filled"
              type="text"
              label="Address"
              name="address"
              id="address"
              value={values.address}
              sx={{ gridColumn: "span 2" }}
             
            />
</Box>
          <Box mt="20px" style={{ display: "flex", flexDirection: "column" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell style={{ width: "220px" }}>{renderLabel("Employee", true)}</TableCell>
                    <TableCell>Cost/Hr</TableCell>
                    <TableCell>Hour</TableCell>
                    <TableCell>TotalCost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goodsInventory.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                      <TextField
                          type="text"
                          name="activity"
                          value={user.activityname}
                         
                        />
                       </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          name="firstname"
                          value={user.firstname}
                         
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          name="costperhr"
                          value={user.costperhr}
                         
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          name="hours"
                          value={user.hours}
                         
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          name="totalcost"
                          value={user.totalcost}
                         
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <br />
                    <div>
                        <TextField
                            label="Sub Total"
                            id="SubTotal"
                            variant="outlined"
                            value={values.subTotal}
                            InputProps={{ readOnly: true }}
                        />
                       <TextField
                            label="Discount%"
                            id="discount"
                            variant="outlined"
                            value={values.discount}
                            // onChange={(e) => setDiscount(e.target.value)}
                        />
                         <TextField
                            label="Discount Amount"
                            id="discountamount"
                            variant="outlined"
                            value={values.discountedamount}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="CGST%"
                            id="cgst"
                            name="cgst"
                            value={values.cgst}
                            // onChange={(e) => setCgst(e.target.value)}
                        />
                        </div>
                        <br/>
                        <div>
                        <TextField
                            label="SGST%"
                            id="sgst"
                            name="sgst"
                            value={values.sgst}
                            // onChange={(e) => setSgst(e.target.value)}
                        />
                        <TextField
                            label="Total GST"
                            id="TotalGst"
                            variant="outlined"
                            value={values.totalGst}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Total Amount"
                            id="TotalAmount"
                            variant="outlined"
                            value={values.totalAmount}
                            InputProps={{ readOnly: true }}
                        />
                    </div>
        </Box>
      </form>
    </>
  );
};

export default ReviseQuotation;
