import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";

const AllClient = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);

  // Get all the login user details
  const {
    userrole,
    username,
    userid,
    // companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const deletesupplier = async (row) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(`Do you want to cancel this customer ?`);

    if (isConfirmed) {
      try {
        const value = row.customerid;
        // console.log(opdnumber)
        await axios.post('/customerdelete',{value});

        // alert("Appointment has been canceled.");

        // After successfully canceling the appointment, refresh the appointment list.
        fetchData();
      } catch (error) {
        // Handle any error here.
        alert("Error cancelling appointment:", error);
      }
    } else {
      // User clicked "Cancel" in the confirmation dialog, do nothing.
    }
  };
  const fetchData = async () => {
    try {
      if (userrole !== undefined) {
        const response = await axios.get("/customerget", {
          params: {
            userrole: userrole, // Include the userrole in the query parameters
          },
        });
        if (response && response.data) {
          setLeads(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userrole]);

  // Column definitions
  const columns = [
    { field: "customerid", headerName: "Client Id", flex: 0.3 },
    { field: "name", headerName: "Client Name", flex: 0.3 },
    {
      field: "email",
      headerName: "Client Email",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    { field: "phoneno", headerName: "Client Phone No", flex: 0.3 },
    // { field: "status", headerName: "Status", flex: 0.3 },
    {
      field: "",
      headerName: "Action",
      flex: 0.3,
      renderCell: (params) => {
        return(
          <>
         <IconButton color="primary">
         <NavLink to={`/modifyclient/${params.row.customerid}`}>
         <EditIcon />
         </NavLink>
        </IconButton>
          <IconButton color="primary" onClick={() => deletesupplier(params.row)}>
         < DeleteIcon />
        </IconButton>
        </>
      )},
    },
  ];

  return (
    <Box m="10px">
      <Header title="All Client" />
      <Box
        m="0px 0 0 0"
        height="73vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={leads}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.customerid}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this lead?
        </DialogContent>
        <DialogActions>
          <Button>No</Button>
          <Button autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllClient;
