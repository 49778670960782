import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../../utils/axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {  useUser } from "../../../contexts/auth";
import { SHA256 } from "crypto-js";
const ModifyEmployeeDetails = () => {
  const {
    token,
    userid,
    userrole,
    username,
    companycode,
  } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { userId } = useParams();
  console.log(userId)
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    phoneno: "",
    address: "",
    role: "",
    password:"",
    conformpassword:"",
    experience:0,
    jobtype:"",
    department:"",
    paidleave:"",
    releasingdate:"",
    joiningdate:"",
    dob:"",
    companyCodes: userrole === "Superadmin" && companycode === 1 ? "" : companycode,
  };
// hashing password
const hashPassword = (password) => {
  return SHA256(password).toString();
};
  const ValidationSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    role: yup.string().required("Role is required"),
    companyCodes: yup.string().required("Company Code is required"),
    email: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
    phoneno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    password: yup.string().min(6).required("Please enter the password"),
    conformpassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
  });
  const [type, setType] = useState("");
  const [icon, setIcon] = useState("show");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [datas, setDatas] = useState("");
//   const [releasingdate,setReleaseingDate]=useState();
const getemployeedetails = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
      const response = await axios.get(`/getemployee/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            companycode: companycode,
            userrole: userrole,
        },
    });
    console.log(response.data[0])
      if (response && response.data) {
        setValues(response.data);
        setDatas(response.data.password)
      }
    }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        setData(response.data);
        setFilteredData(response.data);
        const formattedCodes = response.data.map((company) => company.companycode);
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getemployeedetails();
  }, [companycode,userrole]);

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues
} = useFormik({
    initialValues: { ...initialValues },
   // validationSchema,
    onSubmit: async (values, action) => {
        try {
            const data = new FormData();
            if (datas !== values.password) {
                const hashedPassword = hashPassword(values.password);
                values.password = hashedPassword;
            }
            data.append("document", JSON.stringify(values));
           
            const response = await axios.post(`/updateemployee/${userId}`, data, {
                "Content-Type": "multipart/form-data",
            });

            if (response.status === 200) {
                setSuccess(true);
                setError(false);
                setTimeout(() => {
                    setSuccess(false);
                }, 3000);
            }
        } catch (err) {
            setSuccess(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
        action.resetForm();
      
    },
});
const handleToggle = (e) => {
    
    const gettype = e.target.value;
    console.log(gettype);
    if (gettype === "password") {
      setType("text");
      setIcon("Hide");
    } else {
      setType("password");
      setIcon("Show");
    }
  };
const padNumber = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  };
 
  return (
        <>
          <form onSubmit={handleSubmit}>
            <Box m="20px">
              <h3>Update Employee</h3>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="First Name"
                  onChange={handleChange}
                  value={values.firstname}
                  name="firstname"
                  error={
                    touched.firstname &&
                    !!errors.firstname
                  }
                  helperText={
                    touched.firstname && errors.firstname
                      ? "First name is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Last Name"
                  onChange={handleChange}
                  value={values.lastname}
                  name="lastname"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Email"
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={touched.email && !!errors.email}
                  helperText={
                    touched.email && errors.email
                      ? "Email is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Phone Number"
                  onChange={handleChange}
                  value={values.phoneno}
                  name="phoneno"
                  error={
                    touched.phoneno && !!errors.phoneno
                  }
                  helperText={
                    touched.phoneno && errors.phoneno
                      ? "Phone number is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Address"
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Age"
                  onChange={handleChange}
                  value={values.age}
                  name="age"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={
                    touched.password &&
                    !!errors.password
                  }
                  helperText={
                    touched.password && errors.password
                      ? "Password is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  onChange={handleChange}
                  value={values.conformpassword}
                  name="conformpassword"
                  error={
                    touched.conformpassword &&
                    !!errors.conformpassword
                  }
                  helperText={
                    touched.conformpassword && errors.conformpassword
                      ? "Passwords must match"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

<TextField
                            fullWidth
                            type="date"
                            autoComplete="off"
                            name="joiningdate"
                            id="joiningdate"
                            label="Joining Date"
                            placeholder="Releasing Date"
                            value={values.joiningdate}
                            onChange={handleChange}
                            sx={{ gridColumn: "span 2" }}
                        />
                         <TextField
                            fullWidth
                            type="date"
                            autoComplete="off"
                            name="dob"
                            id="dob"
                            label="Date of Birth"
                            placeholder="Joining Date"
                            value={values.dob}
                            onChange={handleChange}
                            sx={{ gridColumn: "span 2" }}
                        />
                <TextField
                            fullWidth
                            type="date"
                            autoComplete="off"
                            name="releasingdate"
                            id="releasingdate"
                            label="Releasing Date"
                            placeholder="Releasing Date"
                            value={values.releasingdate}
                            onChange={handleChange}
                            sx={{ gridColumn: "span 2" }}
                        />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2" }}
                >
                  <InputLabel id="role">Role</InputLabel>
                  <Select
                    label="Role"
                    id="role"
                    onChange={handleChange}
                    value={values.role}
                    name="role"
                    error={touched.role && !!errors.role}
                  >
                    <MenuItem value="">Select Role</MenuItem>
                    <MenuItem value="Superadmin">Superadmin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Employee">Employee</MenuItem>
                  </Select>
                </FormControl>                  
                  <TextField
                    fullWidth
                    type="text"
                    label="Company Code"
                    value={padNumber(values.companycode, 5)}
                    name="companyCodes"
                    sx={{ gridColumn: "span 2" }}
                  />
    
                 <TextField
                  fullWidth
                  type="text"
                  label="Job Type"
                  onChange={handleChange}
                  value={values.jobtype}
                  name="jobtype"
                  error={
                    touched.jobtype &&
                    !!errors.jobtype
                  }
                  helperText={
                    touched.jobtype && errors.jobtype
                      ? "First name is required"
                      : ""
                  }
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Experience (in years)"
                  onChange={handleChange}
                  value={values.experience}
                  name="experience"
                  sx={{ gridColumn: "span 2" }}
                />
                  <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2" }}
                >
             <InputLabel id="department">Department</InputLabel>
                  <Select
                    label="Department"
                    id="department"
                    onChange={handleChange}
                    value={values.department}
                    name="department"
                    error={touched.department && !!errors.department}
                  >
                    <MenuItem value="">Select Department</MenuItem>
                    <MenuItem value="Business management">Business management</MenuItem>
                    <MenuItem value="Business Administration">Business Administration</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Purchase">Purchase</MenuItem>
                    <MenuItem value="Marketing">Marketing</MenuItem>
                    <MenuItem value="Technical">Technical</MenuItem>
                    <MenuItem value="Non Technical">Non Technical</MenuItem>
                    <MenuItem value="Store">Store</MenuItem>
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                   
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="number"
                  label="Paid Leave"
                  onChange={handleChange}
                  value={values.paidleave}
                  name="paidleave"
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Box>
            </Box>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSuccess}
            autoHideDuration={3000}
            message={successMessage}
          />
        </>
  );
};

export default ModifyEmployeeDetails;
