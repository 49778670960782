import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../assets/charts/Header";
import LineChart from "../../assets/charts/LineChart";
import GeographyChart from "../../assets/charts/GeographyChart";
import BarChart from "../../assets/charts/BarChart";
import StatBox from "../../assets/charts/StatBox";
import ProgressCircle from "../../assets/charts/ProgressCircle";
import { useUser } from "../../contexts/auth";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  
console.log(token,)
  return (
    <Box m="20px">
      <h2>DASHBOARD</h2>
    </Box>
  );
};

export default Dashboard;
