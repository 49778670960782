import React, { useState, useEffect, useRef } from "react";
import Header from "../../../assets/charts/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import { tokens } from "../../../theme";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Box,
    FormControl,
    Autocomplete,
    Snackbar,
} from "@mui/material";
import { useParams } from "react-router-dom";

const EachEmployeeLeaveDetails = () => {

  const { empleavehistoryid } = useParams();
  const { userrole,
    username,
    userid,
    id,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
    loggedIn, } = useUser();
  const isMobile = useMediaQuery("(max-width:600px)");

  const componentRef = useRef();
  const [eachLeave, setEachLeave] = useState({
    empid: "",
    employeerole: "",
    employeename: "",
    leavetype: "",
    leavefrom: "",
    leaveto: "",
    leavestage: "",
    leavecount: "",
    status: "",
    leaverejectreason: "",
    leaveid: "",
    approvedby: "",
    description:""
  });
  const [selectedData, setSelectedData] = useState([]);
  const [rejectReason, setRejectReason] = useState("");
  const [leaveStage, setLeaveStage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {

    const fetchLeaveDetails = async () => {
      try {
        const res = await axios.get(`/GetLeaveEachEmployee/${empleavehistoryid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEachLeave(res.data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmpLeave = async () => {
      try {
        const res = await axios.get(`/GetAllottedPending/${empleavehistoryid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSelectedData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLeaveDetails();
    fetchEmpLeave();
  }, [empleavehistoryid, token, userrole]);

  const handleApprove = async () => {
    try {
      await axios.post(
        `/leaveupdateapprovereject/${empleavehistoryid}`,
        {
          leavestage: "Approved",
          empleavehistoryid: empleavehistoryid,
          empid: eachLeave.empid,
          leaverejectreason: null,
          name: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Role: userrole,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLeaveStage("Approved");
      setOpenSnackbar(true);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async () => {
    if (!rejectReason) {
      alert("Reason is required for rejection.");
      return;
    }
    try {
      await axios.post(
        `/leaveupdateapprovereject/${empleavehistoryid}`,
        {
          leavestage: "Rejected",
          empleavehistoryid: empleavehistoryid,
          leavecount: eachLeave.leavecount,
          leaverejectreason: rejectReason,
          name: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Role:  userrole,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLeaveStage("Rejected");
      setOpenSnackbar(true);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (e) => {
    setRejectReason(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>BBS ERP</title>
      </Helmet>
      <Header />
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} >
        <Box flex={1}>
          <Box mb={2}>
            <h5>Entitled Leaves</h5>
            {selectedData.map((item, index) => (
              <Box key={index} mb={1}>
                {item.leavetype === "sickleave" && (
                  <div>Sick Leaves: {item.entitledleaves}</div>
                )}
                {item.leavetype === "paidleave" && (
                  <div>Paid Leaves: {item.entitledleaves}</div>
                )}
                {item.leavetype === "compoleave" && (
                  <div>Compo Off Leave: {item.entitledleaves}</div>
                )}
              </Box>
            ))}
          </Box>
          <Box mb={2}>
            <h5>Pending Leaves</h5>
            {selectedData.map((item, index) => (
              <Box key={index} mb={1}>
                {item.leavetype
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                : {item.pendingleave}
              </Box>
            ))}
          </Box>
          {eachLeave.leavestage === "Pending Approval" &&  userrole === "Superadmin" && (
            <Box mb={2}>
              <Button variant="contained" color="primary" onClick={handleApprove}>
                Approve
              </Button>
              <Button variant="contained" color="secondary" onClick={handleReject}>
                Reject
              </Button>
            </Box>
          )}
          {eachLeave.leavestage !== "Approved" && eachLeave.leavestage !== "Rejected" && (
            <Box mt={4}>
              <TextField
                label="Leave Description"
                multiline
                rows={2}
                value={rejectReason}
                placeholder="Reason for Rejection"
                onChange={handleEdit}
                variant="outlined"
                fullWidth
              />
            </Box>
          )}
          {eachLeave.leavestage === "Rejected" && (
            <Box mt={2} p={2}>
              <h4 style={{ color: "Red" }}>Rejection Reason: {eachLeave.leaverejectreason}</h4>
            </Box>
          )}
          <Box mt={2} p={2}>
            <h4 style={{ color: "blue" }}>Leave stage: {eachLeave.leavestage}</h4>
          </Box>
        </Box>
        <Box flex={1} ref={componentRef} p={2}>
          <Paper elevation={3}>
            <Box p={2}>
              <form>
                <Box mb={2}>
                  <TextField
                    label="Employee Name"
                    value={eachLeave.employeename}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Leave Type"
                    value={eachLeave.leavetype}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Leave From"
                    type="date"
                    value={eachLeave.leavefrom}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Leave To"
                    type="date"
                    value={eachLeave.leaveto}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Leave Count"
                    type="number"
                    value={eachLeave.leavecount}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Leave Reason"
                    multiline
                    rows={4}
                    value={eachLeave.description}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={`Leave ${leaveStage}`}
        autoHideDuration={3000}
      />
    </>
  );
};

export default EachEmployeeLeaveDetails;
