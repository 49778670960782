import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";

const EstablishmentTimeLine = () => {
  const { establishmentId } = useParams();
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    const fetchTimelineData = async () => {
      try {
        const response = await axios.get(
          `/getUpdatedCompanyById/${establishmentId}`
        );
        const newData = response.data;
        console.log("New Data:", newData);

        // Append new data to the timeline
        setTimelineData(newData);
      } catch (error) {
        console.error("Error fetching timeline data:", error);
      }
    };

    fetchTimelineData();
  }, [establishmentId]);

  // console.log("Timeline Data:", timelineData);

  return (
    <>
      <Helmet>
        <title>ContactTab | Establishment Timeline</title>
      </Helmet>

      <div>
        <h2>Establishment Timeline</h2>
        <ul>
          {timelineData.map((entry, index) => (
            <li key={index}>
              <ul>
               
                {Object.entries(entry).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}</strong>:{" "}
                    {value.oldValue && value.newValue
                      ? `was updated from ${value.oldValue} to ${value.newValue}`
                      : JSON.stringify(value)}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default EstablishmentTimeLine;




