// import { useState, useEffect } from "react";
// import axios from "../utils/axios";
// import Autocomplete from "@mui/material/Autocomplete";
// import {
//     TextField,
//     InputAdornment,
//     IconButton,
// } from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useUser } from "../contexts/auth";

// const CompanycodeSearch = ({onSearchItem}) => {
//     const { userid, userrole, companycode, token } = useUser();
//     const [data, setData] = useState([]);
//     const [open, setOpen] = useState(false); // State to control dropdown visibility
//     const [selectedValue, setSelectedValue] = useState(null); // State to store selected value
//     const renderLabel = (label, isRequired) => (
//         <span>
//             {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
//         </span>
//     );
//     useEffect(() => {
//         const loadData = async () => {
//             await loadAllCompantcode();
//         };
//         loadData();
//     }, [userrole,companycode]);

//     const loadAllCompantcode = async () => {
//         if (companycode !== undefined && userrole !== undefined) {
//             const res = await axios.get("/getCompanyRegistrations", {
//                 // headers: {
//                 //     Authorization: `Bearer ${token}`,
//                 // },
//                 // params: {
//                 //     companycode: companycode,
//                 //     userrole: userrole,
//                 // },
//             });
//             setData(res.data);
//         }
//     };
   
//     const onSearch = (searchTerm, item) => {
//         onSearchItem(item);
//         setSelectedValue(item);
//     };

//     const handleExpandClick = () => {
//         setOpen(!open);
//     };

//     return (
//         <div className="searchbar">
//             <Autocomplete
//                 freeSolo
//                 id="free-solo"
//                 options={data}
//                 getOptionLabel={(option) => `${option.companycode}`}
//                 // sx={{ width: 230 }}
//                 open={open}
//                 onOpen={() => setOpen(true)}
//                 onClose={() => setOpen(false)}
//                 value={selectedValue}
//                 onChange={(event, newValue) => {
//                     onSearch(null, newValue);
//                     setSelectedValue(newValue);
//                 }}
//                 renderInput={(params) =>
//                     <TextField
//                         {...params}
//                         label={renderLabel("Company Code",true)}
//                         placeholder="Select Company Code"
//                         InputProps={{
//                             ...params.InputProps,
//                             endAdornment: (
//                                 <InputAdornment position="end">
//                                     <IconButton onClick={handleExpandClick}>
//                                         <ExpandMoreIcon />
//                                     </IconButton>
//                                 </InputAdornment>
//                             ),
//                         }}
//                     />
//                 }
//                 renderOption={(props, option) => (
//                     <li {...props}>
//                         {option.companycode}
//                     </li>
//                 )}
//             />
//         </div>
//     );
// };

// export default CompanycodeSearch;
import { useState, useEffect } from "react";
import axios from "../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from "../contexts/auth";

const CompanycodeSearch = ({ onSearchItem, defaultCompanyCode }) => {
    const { userid, userrole, companycode, token } = useUser();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            await loadAllCompanyCode();
            if (defaultCompanyCode) {
                setSelectedValue({ companycode: defaultCompanyCode });
                onSearchItem({ companycode: defaultCompanyCode });
            }
        };
        loadData();
    }, [userrole, companycode, defaultCompanyCode]);

    const loadAllCompanyCode = async () => {
        if (companycode !== undefined && userrole !== undefined) {
            const res = await axios.get("/getCompanyRegistrations");
            setData(res.data);
        }
    };

    const onSearch = (searchTerm, item) => {
        onSearchItem(item);
        setSelectedValue(item);
    };

    const handleExpandClick = () => {
        setOpen(!open);
    };

    return (
        <div className="searchbar">
            <Autocomplete
                freeSolo
                id="free-solo"
                options={data}
                getOptionLabel={(option) => `${option.companycode}`}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedValue}
                onChange={(event, newValue) => {
                    onSearch(null, newValue);
                    setSelectedValue(newValue);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Company Code *"
                        placeholder="Select Company Code"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleExpandClick}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.companycode}
                    </li>
                )}
            />
        </div>
    );
};

export default CompanycodeSearch;
