import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import { InputAdornment } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs, { Dayjs } from "dayjs";

import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";

const Addestablishment = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
console.log()
  const [message, setMessage] = useState("");
  const [attachmentonedocument, setAttachmentOneDocument] = useState(null);
  const [attachmenttwodocument, setAttachmentTwoDocument] = useState(null);
  const [attachmentthreedocument, setAttachmentThreeDocument] = useState(null);
  const [attachmentfourdocument, setAttachmentFourDocument] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [registrationdate, setregistrationdate] = useState(new Date());

  const [expirydate, setexpirydate] = useState(new Date());

  // Define Yup validation schema for establishment registration
  const ValidationSchema = Yup.object({
    establishmentname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Establishment name must contain only letters")
      .required("Please enter the establishment name"),
    // establishmentnumber: Yup.string()
    //   .matches(/^[0-9]+$/, "Establishment Number should only contain numbers")
    //   .required("Please enter the establishment number"),
    // emailid: Yup.string()
    //   .email("Email must be a valid")
    //   .required("Please enter the email"),
    // ephonenumber: Yup.string()
    //   .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    //   .min(10, "Mobile Number must be at least 10 characters")
    //   .max(10, "Mobile Number must be at most 10 characters")
    //   .required("Mobile Number is required"),
    // city: Yup.string().matches(
    //   /^[a-zA-Z\s]+$/,
    //   "City should only contain letters"
    // ),
    // state: Yup.string().matches(
    //   /^[a-zA-Z\s]+$/,
    //   "State should only contain letters"
    // ),
    // contactpersonname: Yup.string()
    //   .matches(/^[A-Za-z\s]+$/, "Contact Person name must contain only letters")
    //   .required("Please enter the Contact Person name"),
  });

  const [attachmentOneFileName, setattachmentOneFileName] = useState("");
  const [attachmentTwoFileName, setattachmentTwoFileName] = useState("");
  const [attachmentThreeFileName, setattachmentThreeFileName] = useState("");
  const [attachmentFourFileName, setattachmentFourFileName] = useState("");

  const handleFileChangeAttachmentOne = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentOneFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentOneDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentonedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeAttachmentTwo = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentTwoFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentTwoDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmenttwodocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeAttachmentThree = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentThreeFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentThreeDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentthreedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeAttachmentFour = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentFourFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentFourDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentfourdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const initialValues = {
    establishmentid: "",
    establishmentname: "",
    establishmentnumber: "",
    establishmentdescription: "",
    website: "",
    emailid: "",
    ephonenumber: "",
    address1: "",
    city: "",
    state: "",
    pincode: "",
    contactpersonname: "",
    contactpersonemailid: "",
    industrytype: "",
    registrationdate: null,
    expirydate: null,
    amountpaid: "",
    paymentdetails: "",
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formDataToSend = new FormData();

        formDataToSend.append("formData", JSON.stringify(values));
        formDataToSend.append("username", JSON.stringify(username));
        // console.log(JSON.stringify(values));
        formDataToSend.append("attachmentonedocument", attachmentonedocument);
        formDataToSend.append("attachmenttwodocument", attachmenttwodocument);
        formDataToSend.append(
          "attachmentthreedocument",
          attachmentthreedocument
        );
        formDataToSend.append("attachmentfourdocument", attachmentfourdocument);
        formDataToSend.append(
          "registrationdate",
          JSON.stringify(registrationdate)
        );
        formDataToSend.append("expirydate", JSON.stringify(expirydate));

        // console.log(attachmentonedocument);
        // console.log(username);
        const response = await axios.post(
          "/addEstablishmentRegistration",
          formDataToSend,
          registrationdate,
          expirydate
        );

        if (response.status === 200) {
          setSuccessMessage("Form submitted successfully!");
          setOpenSuccess(true);

          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
          resetForm();
          setAttachmentOneDocument("");

          setAttachmentTwoDocument("");

          setAttachmentThreeDocument("");

          setAttachmentFourDocument("");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const selectDate = (sd) => {
    setregistrationdate(sd);
  };

  // const selectExpiryDate = (sd) => {
  //   setexpirydate(sd);
  // };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <Header
            title="Establishment Registration"
            subtitle="Create a New Establishment Profile"
          />
          <h3>Establishment Details</h3>
          <Box
            display="grid"
            gap="30px" 
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Name"
              onChange={handleChange}
              value={values.establishmentname}
              name="establishmentname"
              error={touched.establishmentname && !!errors.establishmentname} // Show error style if touched and error exists
              helperText={
                touched.establishmentname && errors.establishmentname
                  ? "Establishment name is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Establishment Number"
              onChange={handleChange}
              value={values.establishmentnumber}
              name="establishmentnumber"
              sx={{ gridColumn: "span 2" }}
              error={
                touched.establishmentnumber && !!errors.establishmentnumber
              } // Show error style if touched and error exists
              helperText={
                touched.establishmentnumber && errors.establishmentnumber
                  ? "Establishment number is required"
                  : ""
              }
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Description"
              onChange={handleChange}
              value={values.establishmentdescription}
              name="establishmentdescription"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Website"
              onChange={handleChange}
              value={values.website}
              name="website"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </Box>
        {/* --------------------------Contact Details---------------------- */}
        <Box m="20px">
          <h3>Contact Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Representative Email"
              onChange={handleChange}
              value={values.emailid}
              name="emailid"
              error={touched.emailid && !!errors.emailid} // Show error style if touched and error exists
              helperText={
                touched.emailid && errors.emailid
                  ? "Establishment emailid is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Phone Number"
              onChange={handleChange}
              value={values.ephonenumber}
              name="ephonenumber"
              error={touched.ephonenumber && !!errors.ephonenumber} // Show error style if touched and error exists
              helperText={
                touched.ephonenumber && errors.ephonenumber
                  ? "Establishment phone number is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address1}
              name="address1"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="City"
              onChange={handleChange}
              value={values.city}
              name="city"
              sx={{ gridColumn: "span 2" }}
            />
            {errors.city && touched.city && <div>{errors.city}</div>}

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="State"
              onChange={handleChange}
              value={values.state}
              name="state"
              sx={{ gridColumn: "span 2" }}
            />
            {errors.state && touched.state && <div>{errors.state}</div>}

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Pincode"
              onChange={handleChange}
              value={values.pincode}
              name="pincode"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Cotact Person Name"
              onChange={handleChange}
              value={values.contactpersonname}
              name="contactpersonname"
              error={touched.contactpersonname && !!errors.contactpersonname} // Show error style if touched and error exists
              helperText={
                touched.contactpersonname && errors.contactpersonname
                  ? "Establishment contact person name is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Cotact Person E-Mail"
              onChange={handleChange}
              value={values.contactpersonemailid}
              name="contactpersonemailid"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </Box>

        <Box m="20px">
          <h3>Licence Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Industry Type"
              onChange={handleChange}
              value={values.industrytype}
              name="industrytype"
              sx={{ gridColumn: "span 2" }}
            />
             <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Amount Paid"
              onChange={handleChange}
              value={values.amountpaid}
              name="amountpaid"
              sx={{ gridColumn: "span 2" }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                label=<span> Registration Date </span>
                value={values.registrationdate}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={(registrationdate) => handleChange({ target: { name: "registrationdate", value: registrationdate } })}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                label=<span> Exiry Date </span>
                value={values.expirydate}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={(expirydate) => handleChange({ target: { name: "expirydate", value: expirydate } })}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Payment Details"
              onChange={handleChange}
              value={values.paymentdetails}
              name="paymentdetails"
              sx={{ gridColumn: "span 2" }}
            />

            {/* attach1 */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-1"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentOne}
              // value={attachment1}
              name="attachmentonedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentonedocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentOne}
                    />
                    {attachmentOneFileName && (
                      <div>Selected file: {attachmentOneFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* attach2 */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-2"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentTwo}
              // value={attachment1}
              name="attachmenttwodocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmenttwodocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentTwo}
                    />
                    {attachmentTwoFileName && (
                      <div>Selected file: {attachmentTwoFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {/* attach3 */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-3"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentThree}
              // value={attachment1}
              name="attachmentthreedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentthreedocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentThree}
                    />
                    {attachmentThreeFileName && (
                      <div>Selected file: {attachmentThreeFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-4"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentFour}
              // value={attachment1}
              name="attachmentfourdocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentfourdocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentFour}
                    />
                    {attachmentFourFileName && (
                      <div>Selected file: {attachmentFourFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {/* attachment1 */}
            {/* <Grid
              item
              xs={12}
              className="text-center"
              lg={6}
              md={6}
              sm={12}
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "left" }}
                >
                  <Typography variant="subtitle1">Attachment1</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <input
                    type="file"
                    accept=".pdf"
                    name="adhardocument"
                    onChange={handleFileChangeAdhar}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Box
              display="flex"
              justifyContent="end"
              mt="20px"
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default Addestablishment;
