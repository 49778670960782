import { BrowserRouter as Router, Routes, Route ,Navigate} from 'react-router-dom';
import ProtectedRoute from './contexts/ProtectedRoute';
import SignIn from "./pages/login";
import Dashboard from './components/Dashboard';
import { UserProvider } from "./contexts/auth.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "./common/sidebar/sidebar.jsx";
import Topbar from "./common/topbar/topbar.jsx";
import Addestablishment from "./components/Establishment/AddEstablishment/addestablishment.jsx";
import Allestablishment from "./components/Establishment/AllEstablishment/allestablishment.jsx";
import EstablishmentUpdate from "./components/Establishment/EstablishmentUpdate/establishmentupdate.jsx";
import EstablishmentTimeLine from "./components/Establishment/EstablishmentUpdate/timeline.jsx";
import UpdateUser from "./components/Users/UpdateUser/updateuser.jsx";
import Alluser from "./components/Users/AllUser/alluser.jsx";
import Adduser from "./components/Users/AddUser/adduser.jsx";
import { ColorModeContext, useMode } from "./common/theme/theme.jsx";
import "./App.css";
import Landing from "./components/Landing";
import CostperHour from './components/Users/CostperHour/CostperHour.js';
import QutationPlaning from './components/QutationPlaning/QutationPlaning.js';
import AllQuotationPlaning from './components/QutationPlaning/AllQuotationPlaning.js';
import ReviseQuotation from './components/QutationPlaning/ReviseQuotation.js';
import HistoryTab from './components/QutationPlaning/HistoryTab.js';
import QuotationDetails from './components/QutationPlaning/QuotationDetails.js';
import AddQuotation from './components/Quotation/AddQuotation.js';
import AllQuotation from './components/Quotation/AllQuotation.js';
import AllClient from './components/Client/AllClient.jsx';
import ModifyClientDetails from './components/Client/ModifyClientDetails.jsx';
import AddClient from './components/Client/AddClient.jsx';
import Invoice from './components/Invoice/Invoice.js';
import AllInvoice from './components/Invoice/AllInvoice.js';
import InvoiceModify from './components/Invoice/InvoiceModify.js';
import InvoiceHistory from './components/Invoice/InvoiceHistory.js';
import ProformaInvoice from './components/Porforma/ProformaInvoice.js';
import AllProformaInvoice from './components/Porforma/AllProformaInvoice.js';
import AddHoliday from './components/Holiday/AddHoliday.js';
import ListViewHoliday from './components/Holiday/ListViewHoliday.js';
import LeaveEntry from './components/Users/leave/LeaveEntry.js';
import MyLeaveDetailsList from './components/Users/leave/MyLeaveDetailsList.js';
import AllEmployeeListViewLeaves from './components/Users/leave/AllEmployeeListViewLeaves.js';
import EditHoliday from './components/Holiday/EditHoliday.js';
import EachEmployeeLeaveDetails from './components/Users/leave/EachEmployeeLeaveDetails.js';
import EmployeeLeaveForm from './components/Users/leave/EmployeeLeaveForm.js';
import ModifyCostperHour from './components/Users/CostperHour/ModifyCostperHour.js';
import ModifyEmployeeDetails from './components/Users/AddUser/ModifyEmployeeDetails.js';
// import { generateToken ,messaging} from './components/Notifications/firebase.js';
// import { onMessage } from 'firebase/messaging';
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <Router>
     <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <UserProvider>
            <Sidebar isSidebar={isSidebar} />
          </UserProvider>

          <main className="content">
            <UserProvider>
              <Topbar setIsSidebar={setIsSidebar} />
            </UserProvider>

            <Routes>
              <Route
                path="/dashboard"
                element={
                  <UserProvider>
                    <Dashboard />
                  </UserProvider>
                }
              />
              <Route
                path="/add-establishment"
                element={
                  <UserProvider>
                    <Addestablishment />
                  </UserProvider>
                }
              />

              <Route
                path="/all-establishment"
                element={
                  <UserProvider>
                <Allestablishment />
                </UserProvider>
              }
              />

              <Route
                path="/update-establishment/:establishmentId"
                element={
                  <UserProvider>
                    <EstablishmentUpdate />
                  </UserProvider>
                }
              />

              <Route
                path="/establishmenttimeline/:establishmentId"
                element={
                  <UserProvider>
                    <EstablishmentTimeLine />
                  </UserProvider>
                }
              />

              <Route
                path="/add-user"
                element={
                  <UserProvider>
                    <Adduser />
                  </UserProvider>
                }
              />
               <Route
                path="/modifyemployee/:userId"
                element={
                  <UserProvider>
                    <ModifyEmployeeDetails />
                  </UserProvider>
                }
              />
              <Route
                path="/employeecost"
                element={
                  <UserProvider>
                    <CostperHour/>
                  </UserProvider>
                }
              />
               <Route
                  path="/editemployeecost/:userId"
                  element={
                    <UserProvider>
                      <ModifyCostperHour/>
                    </UserProvider>
                  }
                />
               <Route
                  path="/applyleave"
                  element={
                    <UserProvider>
                      <EmployeeLeaveForm/>
                    </UserProvider>
                  }
                />
              <Route
                path="/all-user"
                element={
                  <UserProvider>
                <Alluser />
                </UserProvider>
              }
              />

              <Route
                path="/update-user/:userId"
                element={
                  <UserProvider>
                    <UpdateUser />
                  </UserProvider>
                }
              />
               <Route
                path="/new-qutation"
                element={
                  <UserProvider>
                    <QutationPlaning/>
                  </UserProvider>
                }
              />
               <Route
                path="/all-qutation"
                element={
                  <UserProvider>
                    <AllQuotationPlaning/>
                  </UserProvider>
                }
              />
               <Route
                path="/qutationplainingdetails/:quotationno"
                element={
                  <UserProvider>
                    <HistoryTab/>
                  </UserProvider>
                }
              />
                <Route
                path="/qutationplainingmodify/:quotationno"
                element={
                  <UserProvider>
                    <QuotationDetails/>
                  </UserProvider>
                }
              />
               <Route
                path="/addquotation"
                element={
                  <UserProvider>
                    <AddQuotation/>
                  </UserProvider>
                }
              />
              <Route
                path="/allquotations"
                element={
                  <UserProvider>
                    <AllQuotation/>
                  </UserProvider>
                }
              />
               <Route
                path="/addinvoice"
                element={
                  <UserProvider>
                    <Invoice/>
                  </UserProvider>
                }
              />
              <Route
                path="/allinvoices"
                element={
                  <UserProvider>
                    <AllInvoice/>
                  </UserProvider>
                }
              />
              <Route
                path="/invoicegmodify/:invoiceno"
                element={
                  <UserProvider>
                    <InvoiceModify/>
                  </UserProvider>
                }
              />
                <Route
                path="/invoicedetails/:invoiceno"
                element={
                  <UserProvider>
                    <InvoiceHistory/>
                  </UserProvider>
                }
              />
               <Route
                path="/addproformainvoice"
                element={
                  <UserProvider>
                    <ProformaInvoice/>
                  </UserProvider>
                }
              />
              <Route
                path="/allproformainvoice"
                element={
                  <UserProvider>
                    <AllProformaInvoice/>
                  </UserProvider>
                }
              />
               <Route
                  path="/client"
                  element={
                    <UserProvider>
                      <AddClient/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/modifyclient/:customerid"
                  element={
                    <UserProvider>
                      <ModifyClientDetails/>
                    </UserProvider>
                  }
                />
                <Route
                  path="/allclient"
                  element={
                    <UserProvider>
                      <AllClient/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/addholiday"
                  element={
                    <UserProvider>
                      <AddHoliday/>
                    </UserProvider>
                  }
                />
                <Route
                  path="/listviewholidays"
                  element={
                    <UserProvider>
                      <ListViewHoliday/>
                    </UserProvider>
                  }
                />
                  <Route
                  path="/allemployeeleaveslistview"
                  element={
                    <UserProvider>
                    <AllEmployeeListViewLeaves/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/employeeleavelist"
                  element={
                    <UserProvider>
                      <MyLeaveDetailsList/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/allemployeeleaveslistview/:empleavehistoryid"
                  element={
                    <UserProvider>
                      <EachEmployeeLeaveDetails/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/applyleave"
                  element={
                    <UserProvider>
                      <EmployeeLeaveForm/>
                    </UserProvider>
                  }
                />
                  <Route
                  path="/leaveentry"
                  element={
                    <UserProvider>
                      <LeaveEntry/>
                    </UserProvider>
                  }
                />
                 <Route
                  path="/editholiday/:holidayid/edit"
                  element={
                    <UserProvider>
                      <EditHoliday/>
                    </UserProvider>
                  }
                />
               <Route path="/login" element={<SignIn />} />
               <Route path="/" element={<Landing />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
   </Router>
  );
}

export default App;


