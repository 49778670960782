import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReviseQuotation from './ReviseQuotation';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { useUser } from '../../contexts/auth';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HistoryTab = () => {
  const { quotationno } = useParams();
  const { companycode, userrole, token } = useUser();
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/quotationdetail/${quotationno}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companycode,
            userrole,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [quotationno, companycode, userrole, token]);

  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.quotationno]) {
      acc[item.quotationno] = [];
    }
    acc[item.quotationno].push(item);
    return acc;
  }, {});

  const quotationNumbers = Object.keys(groupedData).sort((a, b) => {
    const [baseA, suffixA] = a.split('-');
    const [baseB, suffixB] = b.split('-');

    if (baseA !== baseB) {
      return baseA.localeCompare(baseB);
    }

    if (suffixA && suffixB) {
      return parseInt(suffixB) - parseInt(suffixA);
    }

    return suffixA ? -1 : 1;
  });

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="static">
        <StyledTabs value={value} onChange={handleChange} aria-label="history tabs">
          {quotationNumbers.map((quotationno, index) => (
            <StyledTab key={quotationno} label={quotationno} {...a11yProps(index)} />
          ))}
        </StyledTabs>
      </AppBar>
      {quotationNumbers.map((quotationno, index) => (
        <TabPanel key={quotationno} value={value} index={index}>
          <ReviseQuotation data={groupedData[quotationno]} />
        </TabPanel>
      ))}
    </Box>
  );
};

export default HistoryTab;
