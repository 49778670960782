import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import axios from "../../../utils/axios";
import * as Yup from "yup"; // Import Yup for validation
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";

const EstablishmentUpdate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { establishmentId } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    establishmentid: "",
    establishmentname: "",
    establishmentnumber: "",
    establishmentdescription: "",
    website: "",
    emailid: "",
    ephonenumber: "",
    address1: "",
    city: "",
    state: "",
    pincode: "",
    contactpersonname: "",
    contactpersonemailid: "",
    industrytype: "",
    registrationdate: null,
    expirydate: null,
    amountpaid: "",
    paymentdetails: "",
  });

  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  //name
  const [attachmentOneFileName, setattachmentOneFileName] = useState("");
  const [attachmentTwoFileName, setattachmentTwoFileName] = useState("");
  const [attachmentThreeFileName, setattachmentThreeFileName] = useState("");
  const [attachmentFourFileName, setattachmentFourFileName] = useState("");

  //document
  const [attachmentonedocument, setAttachmentOneDocument] = useState(null);
  const [attachmenttwodocument, setAttachmentTwoDocument] = useState(null);
  const [attachmentthreedocument, setAttachmentThreeDocument] = useState(null);
  const [attachmentfourdocument, setAttachmentFourDocument] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [datas, setData] = useState({});
  const [attachment1, setAttachment1document] = useState(null);

  const loadEstablishment = async () => {
    const res = await axios.get(`/getEstablishmentdoc/${establishmentId}`, {});
    setData(res.data.client);
    // console.log(res.data.client);
  };

  const handleFileChangeAttachmentOne = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentOneFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentOneDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentonedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeAttachmentTwo = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentTwoFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentTwoDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmenttwodocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  const handleFileChangeAttachmentThree = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentThreeFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentThreeDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentthreedocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  const handleFileChangeAttachmentFour = (event) => {
    const fileName = event.target.files[0].name;
    setattachmentFourFileName(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAttachmentFourDocument(selectedFiles);
      handleChange({
        target: {
          name: "attachmentfourdocument",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`/getCompanyById/${establishmentId}`);

        const establishmentData = response.data;
        // console.log(response.data);
        setCompanyData({ ...establishmentData });
        setValues({
          ...establishmentData,
        });
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();

    if (establishmentId) {
      // Ensure itemId exists before fetching data

      loadEstablishment();
    }
  }, [establishmentId]);

  const ValidationSchema = Yup.object({
    establishmentname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Establishment name must contain only letters")
      .required("Please enter the establishment name"),
    // establishmentnumber: Yup.string()
    //   .matches(/^[0-9]+$/, "Establishment Number should only contain numbers")
    //   .required("Please enter the establishment number"),
    // emailid: Yup.string()
    //   .email("Email must be a valid")
    //   .required("Please enter the email"),
    // ephonenumber: Yup.string()
    //   .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
    //   .min(10, "Mobile Number must be at least 10 characters")
    //   .max(10, "Mobile Number must be at most 10 characters")
    //   .required("Mobile Number is required"),
    // city: Yup.string().matches(
    //   /^[a-zA-Z\s]+$/,
    //   "City should only contain letters"
    // ),
    // state: Yup.string().matches(
    //   /^[a-zA-Z\s]+$/,
    //   "State should only contain letters"
    // ),
    // contactpersonname: Yup.string()
    //   .matches(/^[A-Za-z\s]+$/, "Contact Person name must contain only letters")
    //   .required("Please enter the Contact Person name"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues: companyData,
    validationSchema: ValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formDataToSend = new FormData();

        formDataToSend.append("companyData", JSON.stringify(values));
        // formDataToSend.append("attachment1", attachment1);

        formDataToSend.append("attachmentonedocument", attachmentonedocument);
        formDataToSend.append("attachmenttwodocument", attachmenttwodocument);
        formDataToSend.append(
          "attachmentthreedocument",
          attachmentthreedocument
        );
        formDataToSend.append("attachmentfourdocument", attachmentfourdocument);
        formDataToSend.append("username", username);

        console.log(attachmentonedocument);
        await axios.post(`/updateCompany/${establishmentId}`, formDataToSend);

        setSuccessMessage("Company details updated successfully!");
        setOpenSuccess(true);
        setEditMode(false); // Disable edit mode after successful submission

        // Hide success message after 3 seconds (3000 milliseconds)
        setTimeout(() => {
          setOpenSuccess(false);
          setSuccessMessage("");
        }, 3000);
      } catch (error) {
        console.error("Error updating company:", error);
      }
    },
  });

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  if (!companyData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>ContactTab | Establishment List Update </title>
      </Helmet>


      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <Button
            onClick={() =>
              navigate(`/establishmenttimeline/${establishmentId}`)
            }
            variant="contained"
          >
            Timeline
          </Button>

          <Header
            title="Establishment Update"
            subtitle="Update your establishment details"
          />
          <h3>Establishment Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Name"
              onChange={handleChange}
              value={values.establishmentname}
              name="establishmentname"
              error={touched.establishmentname && !!errors.establishmentname} // Show error style if touched and error exists
              helperText={
                touched.establishmentname && errors.establishmentname
                  ? "Establishment name is required"
                  : ""
              }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Establishment Number"
              onChange={handleChange}
              value={values.establishmentnumber}
              name="establishmentnumber"
              sx={{ gridColumn: "span 2" }}
              error={
                touched.establishmentnumber && !!errors.establishmentnumber
              } // Show error style if touched and error exists
              helperText={
                touched.establishmentnumber && errors.establishmentnumber
                  ? "Establishment number is required"
                  : ""
              }
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Description"
              onChange={handleChange}
              value={values.establishmentdescription}
              name="establishmentdescription"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Establishment Website"
              onChange={handleChange}
              value={values.website}
              name="website"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </Box>
        <Box m="20px">
          <h3>Contact Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Representative Email"
              onChange={handleChange}
              value={values.emailid || ""}
              name="emailid"
              // error={touched.emailid && !!errors.emailid} // Show error style if touched and error exists
              // helperText={
              //   touched.emailid && errors.emailid
              //     ? "Establishment emailid is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Phone Number"
              onChange={handleChange}
              value={values.ephonenumber}
              name="ephonenumber"
              // error={touched.ephonenumber && !!errors.ephonenumber} // Show error style if touched and error exists
              // helperText={
              //   touched.ephonenumber && errors.ephonenumber
              //     ? "Establishment phone number is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onChange={handleChange}
              value={values.address1}
              name="address1"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="City"
              onChange={handleChange}
              value={values.city}
              name="city"
              sx={{ gridColumn: "span 2" }}
            />
            {/* {errors.city && touched.city && <div>{errors.city}</div>} */}

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="State"
              onChange={handleChange}
              value={values.state}
              name="state"
              sx={{ gridColumn: "span 2" }}
            />
            {/* {errors.state && touched.state && <div>{errors.state}</div>} */}

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Pincode"
              onChange={handleChange}
              value={values.pincode}
              name="pincode"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Cotact Person Name"
              onChange={handleChange}
              value={values.contactpersonname}
              name="contactpersonname"
              // error={touched.contactpersonname && !!errors.contactpersonname} // Show error style if touched and error exists
              // helperText={
              // //   touched.contactpersonname && errors.contactpersonname
              // //     ? "Establishment contact person name is required"
              // //     : ""
              // // }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Cotact Person E-Mail"
              onChange={handleChange}
              value={values.contactpersonemailid}
              name="contactpersonemailid"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </Box>
        <Box m="20px">
          <h3>Licence Details</h3>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Industry Type"
              onChange={handleChange}
              value={values.industrytype}
              name="industrytype"
              sx={{ gridColumn: "span 2" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                label=<span> Registration Date </span>
                value={dayjs(
                  companyData.registrationdate
                    ? dayjs(companyData.registrationdate)
                    : null
                )}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                // onChange={(e) => selectDate(e["$d"])}
                // onChange={handleDateChange}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>


            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="combo-box-demo"
                label=<span> Expiry Date </span>
                value={dayjs(
                  companyData.expirydate
                    ? dayjs(companyData.expirydate)
                    : null
                )}
                slotProps={{ textField: { size: "small" } }}
                style={{
                  marginTop: "2rem",
                }}
                onChange={(expirydate) => handleChange({ target: { name: "expirydate", value: expirydate } })}
                // onChange={(e) => selectDate(e["$d"])}
                // onChange={handleDateChange}
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Amount Paid"
              onChange={handleChange}
              value={values.amountpaid}
              name="amountpaid"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Payment Details"
              onChange={handleChange}
              value={values.paymentdetails}
              name="paymentdetails"
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-1"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentOne}
              value={datas.attachment1}
              name="attachmentonedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentonedocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentOne}
                    />
                    {attachmentOneFileName && (
                      <div>Selected file: {attachmentOneFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* attach2 */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-2"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentTwo}
              value={datas.attachment2}
              name="attachmenttwodocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmenttwodocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentTwo}
                    />
                    {attachmentTwoFileName && (
                      <div>Selected file: {attachmentTwoFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {/* attach3 */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-3"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentThree}
              value={datas.attachment3}
              name="attachmentthreedocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentthreedocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentThree}
                    />
                    {attachmentThreeFileName && (
                      <div>Selected file: {attachmentThreeFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Attachment-4"
              // onBlur={handleBlur}
              onChange={handleFileChangeAttachmentFour}
              value={datas.attachment4}
              name="attachmentfourdocument"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                readOnly: true, // Disable text input
                endAdornment: (
                  <InputAdornment position="start">
                    <UploadFileIcon
                      style={{ cursor: "pointer" }}
                      //onClick={() => handleIconClick()} // Corrected syntax
                    />
                    <input
                      type="file"
                      accept=".pdf"
                      name="attachmentfourdocument"
                      //ref={fileInputRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                      onChange={handleFileChangeAttachmentFour}
                    />
                    {attachmentFourFileName && (
                      <div>Selected file: {attachmentFourFileName}</div>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          mt="20px"
        >
          <Button
            type="submit"
            color="secondary"
            variant="contained"
          >
            SUBMIT
          </Button>
        </Box>
      </form>
      {/* Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default EstablishmentUpdate;
