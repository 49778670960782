import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import { IoCheckmarkSharp } from "react-icons/io5";
import Header from "../../../assets/charts/Header";
import {

    Checkbox,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    Autocomplete,
    Snackbar,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import { useFormik } from "formik";

const initialValues = {
    leavehour: "",
    description: "",
    leavestage: "",
};

const EmployeeLeaveForm = () => {
    const { userrole,
        username,
        id,
        userid,
        companycode,
        isAuthRole,
        isAuthorized,
        token,
        loggedIn, } = useUser();
        const padNumber = (number, length) => {
            let str = '' + number;
            while (str.length < length) {
                str = '0' + str;
            }
            return str;
        }
    const [successMessage, setSuccessMessage] = useState("");

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
    } = useFormik({
        initialValues,
        // validationSchema: empleave,

        onSubmit: async (values, action) => {
            try {
                if (companycode !== undefined && userrole !== undefined) {
                console.log(values);
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("id", JSON.stringify(id));
                data.append("getRole", JSON.stringify(userrole));
                data.append("getName", JSON.stringify(username));
                data.append("numberOfDays", JSON.stringify(numberOfDays));
                data.append("leaveFrom", JSON.stringify(leaveFrom));
                data.append("leaveTo", JSON.stringify(leaveTo));
                data.append("leaveType", JSON.stringify(leaveType));
                // data.append("leaveType", JSON.stringify(description));

                data.append("currentDate", JSON.stringify(currentDate));
                console.log( JSON.stringify(leaveFrom));
                //will post all form data to database
                console.log(padNumber(companycode, 5))
                const response = await axios.post("/EmployeeLeavePost", data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        companycode: padNumber(companycode, 5),
                        userrole: userrole,
                    },
                });
                // console.log(response + "here i have resoponse for post");
                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                    setSuccessMessage("Leave applied successfully.");
                } else {
                    setSuccess(false);
                    setError(true);
                    const errorData = response.data;
                    console.log(errorData);
                    // alert(response.data);
                }
            }
         } catch (err) {
                console.log(err);
                alert("Available leaves are less than applying leaves. ");
            }
            action.resetForm();
        },
    });

    /////////////
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    // const token = sessionStorage.getItem("jwtToken");

    let time = new Date().toLocaleTimeString();
    const [currentTime, setCurrentTime] = useState(time);

    let Showdate = new Date();
    let ShowTodaydate =
        Showdate.getFullYear() +
        "-" +
        (Showdate.getMonth() + 1) +
        "-" +
        Showdate.getDate();
    //////////

    const [currentDate, setCurrentDate] = useState(ShowTodaydate);

    const [datas, setDatas] = useState([]);
    const [pendingdatas, setPendingDatas] = useState([]);
    const [leaveType, setLeaveType] = useState();
    const [empSickleave, setSickLeave] = useState();
    const [empCompoleave, setCompoLeave] = useState();
    const [empPaidleave, setPaidLeave] = useState();


    //allotted leaves
    const loadAllottedLeaves = async () => {
        try {
            const res = await axios.get(`/AllottedLeaves/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setDatas(res.data); // Assuming setDatas is a state update function
            } else {
                console.error(`Failed to fetch allotted leaves. Status: ${res.status}`);
                // Handle error state or feedback to the user
            }
        } catch (error) {
            console.error('Error fetching allotted leaves:', error.message);
            // Handle error state or feedback to the user
        }
    };



    //Pending leaves
    const loadPendingLeaves = async (data) => {
        const res = await axios.get(`/PendingLeaves/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        // console.log(res.data);
        console.log(":::::::::::::", res.data);
        setPendingDatas(res.data);
    };

    const today = new Date().toISOString().split("T")[0];

    const [leaveFrom, setLeaveFrom] = useState(today);
    const [leaveTo, setLeaveTo] = useState(today);
    const [isHalfDay, setIsHalfDay] = useState(false);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [dateError, setDateError] = useState("");
    const [canSubmit, setCanSubmit] = useState(true);
    const [holidays, setHolidays] = useState([]);


    const loadholidays = async (data) => {
        const res = await axios.get(`/Holidays`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const allholidays = res.data[0];
        setHolidays(allholidays);
        // console.log("all holiday",allholidays);
    };

    const UpdateTime = () => {
        let time = new Date().toLocaleTimeString();
        setCurrentTime(time);
    };

    /////////////////

    const calculateNumberOfDays = (leaveFrom, leaveTo, isHalfDay, holidays) => {
        if (leaveFrom && leaveTo && dateError === "") {
            const from = new Date(leaveFrom);
            const to = new Date(leaveTo);

            const totalDays = isHalfDay
                ? 0.5
                : Math.ceil((to - from) / (1000 * 3600 * 24)) + 1;

            let weekends = 0;
            let holidayCount = 0;

            // Create a Set of holiday dates for faster lookup
            const holidaySet = new Set(holidays.map((holiday) => holiday.holidaydate));
            // console.log("sdfsdfsd1111",holidaySet);

            for (
                let date = new Date(leaveFrom);
                date <= to;
                date.setDate(date.getDate() + 1)
            ) {
                const day = date.getDay();
                const formattedDate = date.toISOString().split("T")[0];

                if (day === 0 || day === 6) {
                    weekends++;
                }

                if (holidaySet.has(formattedDate)) {
                    holidayCount++;
                }
            }

            return totalDays - weekends - holidayCount;
        }
        return 0;
    };

    const handleHalfDayChange = () => {
        if (leaveFrom === leaveTo) {
            setIsHalfDay(!isHalfDay);
        }
    };

    setTimeout(UpdateTime, 1000);

    const handleLeaveTypeChange = (event) => {
        setLeaveType(event.target.value);
    };

    //from
    const handleChangeLeaveFrom = (event) => {
        const newLeaveFrom = event.target.value;
        setLeaveFrom(newLeaveFrom);

        if (newLeaveFrom && leaveTo) {
            const from = new Date(newLeaveFrom);
            const to = new Date(leaveTo);

            if (from > to) {
                setDateError(
                    "Leave From date must be before or the same as Leave To date"
                );
            } else {
                setDateError(""); // Clear the error if the dates are valid

                const calculatedDays = calculateNumberOfDays(
                    newLeaveFrom,
                    leaveTo,
                    isHalfDay,
                    holidays
                );
                setNumberOfDays(calculatedDays);

                // checkAvailableLeaves(calculatedDays); // Check available leaves
            }
        }
    };

    // //to
    const handleChangeLeaveTo = (event) => {
        const newLeaveTo = event.target.value;
        setLeaveTo(newLeaveTo);

        if (leaveFrom && newLeaveTo) {
            const from = new Date(leaveFrom);
            const to = new Date(newLeaveTo);

            if (from > to) {
                setDateError(
                    "Leave To date must be after or the same as Leave From date"
                );
            } else {
                setDateError(""); // Clear the error if the dates are valid

                const calculatedDays = calculateNumberOfDays(
                    leaveFrom,
                    newLeaveTo,
                    isHalfDay,
                    holidays
                );
                setNumberOfDays(calculatedDays);

                // checkAvailableLeaves(calculatedDays); // Check available leaves
            }
        }
    };

    useEffect(() => {
        if (leaveFrom != leaveTo) {
            setIsHalfDay(false);
        }
        const calculatedDays = calculateNumberOfDays(
            leaveFrom,
            leaveTo,
            isHalfDay,
            holidays
        );

        setNumberOfDays(calculatedDays);
    }, [leaveFrom, leaveTo, isHalfDay]);

    useEffect(() => {
        loadholidays();
        loadAllottedLeaves();
        loadPendingLeaves();

        // handleOrder();
    }, [id, token,companycode, userrole]);

    const [selectedData, setSelectedData] = useState([]);
    useEffect(() => {
        // This code will run whenever datas changes
        const mappedData = datas.map((item) => ({
            leavetype: item.leavetype,
            entitledleaves: item.entitledleaves,
        }));
        setSelectedData(mappedData); // Store the mapped data in the state variable

        // console.log("Selected Data:", mappedData);
    }, [datas]);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccess(false);
        setError(false);
    };
    return (
        <>  <Box m="20px">
            <Header title="Apply Leave" />
            <Helmet>
                <title>BBS ERP</title>
            </Helmet>
            {success && (
                <Snackbar
                    open={success}
                    autoHideDuration={30000} // 60 seconds
                    onClose={handleClose}
                    message="Form submitted successfully!"
                />
            )}
            {error && (
                <Snackbar
                    open={error}
                    autoHideDuration={3000} // 60 seconds
                    onClose={handleClose}
                    message="Error submitting form. Please try again later."
                />
            )}

            <Box>
                {/* <p>Entitled Leaves -</p> */}
                <div >
                    {selectedData.map((item, index) => (
                        <div key={index} >
                            {item.leavetype === "paidleave" && (
                                <span> Entitled Paid Leave: {item.entitledleaves}</span>
                            )}
                            {/* {item.leavetype === "compoleave" && (
                                <span>Compo Off Leave: {item.entitledleaves}</span>
                            )} */}

                        </div>
                    ))}
                </div>
            </Box>
            <br />
            <Box>
                {/* <p>Pending Leaves -</p> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {pendingdatas.map((item, index) => {
                        // Create a mapping from leavetype values to display names
                        const leaveTypeMapping = {
                            paidleave: " Pending Leaves",
                            // sickleave: "Sick Leave",
                            compoleave: "Compo Off Leave",
                            // Add more mappings for other leave types if needed
                        };

                        // Get the display name based on the leavetype
                        const displayName =
                            leaveTypeMapping[item.leavetype] || item.leavetype;

                        return (
                            <div key={index} className="me-3 displayleaves">
                                {displayName}: {item.pendingleave}
                            </div>
                        );
                    })}
                </div>
            </Box>

            <br />
            <div >
                <form

                    onSubmit={handleSubmit}

                >
                    {error && (
                        <div >
                            Error submitting form. Please try again later.
                        </div>
                    )}

                    <Box sx={{ display: 'flex', gap: 2 }}>

                        <Select
                            fullWidth
                            label="Leave Type"
                            type="text"
                            autoComplete="off"
                            name="leavetype"
                            id="leavetype"
                            value={leaveType}
                            onChange={handleLeaveTypeChange}
                            onBlur={handleBlur}
                            placeholder="Leavetype"
                        >
                            <MenuItem value="">
                                <em>Select leave type</em>
                            </MenuItem>
                            {/* <MenuItem value="sickleave">Sick Leave</MenuItem> */}
                            <MenuItem value="paidleave">Paid Leave</MenuItem>
                            {/* <MenuItem value="compoleave">Compo Leave</MenuItem> */}
                        </Select>
                        {errors.leavetype && touched.leavetype ? (
                            <p >{errors.leavetype}</p>
                        ) : null}

                        <TextField
                            fullWidth
                            rows="2"
                            cols="30"
                            type="text"
                            label="Leave Reason"
                            autoComplete="off"
                            name="description"
                            id="description"
                            placeholder="Description"
                            value={values.description}
                            onChange={handleChange}
                        />

                        {errors.description && touched.description ? (
                            <p >{errors.description}</p>
                        ) : null}


                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', gap: 2 }}>

                        <TextField
                            fullWidth
                            type="date"
                            autoComplete="off"
                            name="leavefrom"
                            id="leavefrom"
                            label="Leave From"
                            placeholder="Leave From"
                            value={leaveFrom}
                            onChange={handleChangeLeaveFrom}
                            required
                        />

                        {errors.leavefrom && touched.leavefrom ? (
                            <p >{errors.leavefrom}</p>
                        ) : null}

                        <TextField
                            fullWidth
                            type="date"
                            autoComplete="off"
                            name="leaveto"
                            id="leaveto"
                            label="Leave To"
                            placeholder="Leave To"
                            value={leaveTo}
                            onChange={handleChangeLeaveTo}
                            required
                        />

                        {errors.leaveto && touched.leaveto ? (
                            <p >{errors.leaveto}</p>
                        ) : null}
                        {dateError && <p >{dateError}</p>}

                    </Box>



                    <input
                        type="hidden"
                        //   autoComplete="off"
                        className="time"
                        name="leavedate"
                        id="leavedate"
                        placeholder="Leave To"
                        value={ShowTodaydate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {errors.leavedate && touched.leavedate ? (
                        <p className="form-erroremployee">{errors.leavedate}</p>
                    ) : null}


                    <input
                        type="hidden"
                        name="leavestage"
                        value={values.leavestage}
                        onChange={handleChange}
                    />
                    <div >
                        <div>
                            {leaveFrom === leaveTo && (
                                <>
                                    <label
                                        htmlFor=""

                                    >
                                        Half Day
                                    </label>
                                    <Checkbox
                                        type="checkbox"
                                        checked={isHalfDay}
                                        onChange={handleHalfDayChange}
                                    />
                                </>
                            )}

                            <div className="numofdays-para" style={{ marginTop: '20px' }}>

                                <p>Number of days: {numberOfDays}</p>


                            </div>

                        </div>
                    </div>

                    <div className="modal-buttons">
                    <Box display="flex" justifyContent="initial" mt="20px">
                        <Button type="submit" color="secondary" variant="contained"
                                onClick={async () => {
                                    handleChange({
                                        target: {
                                            name: "leavestage",
                                            value: "Pending Approval",
                                        },
                                    });

                                    try {
                                        // await handleOrder();
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                                disabled={!canSubmit}
                            >
                                Apply Leave
                            </Button>
                            <Button
                                className="TextField-button"
                                onClick={() => {
                                    handleReset();
                                    setLeaveFrom("");
                                    setLeaveTo("");
                                    setLeaveType("");
                                    setIsHalfDay("");

                                }}
                                type="button"
                            >
                                Reset
                            </Button>
                        </Box>
                    </div>
                </form>
            </div>
        </Box>
        </>
    );
};

export default EmployeeLeaveForm;
