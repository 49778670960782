import React, { useState, useEffect } from "react";
import Header from "../../assets/charts/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../utils/axios";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/auth";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from 'yup';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Box,
    FormControl,
    Autocomplete,
    Snackbar,
} from "@mui/material";
import CompanycodeSearch from "../../common/CompanycodeSearch";

const Invoice = () => {
    const { userid, userrole, companycode, token } = useUser();
    const initialValues = {
        companyCodes: "",
        projectcode: "",
        address: "",
    };
    const padNumber = (number, length) => {
        let str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    };
    const userTemp = {
        activitycode: "",
        quantity: "",
        price: "",
        totalcost: ""
    };
    const [searchValues, setSearchValues] = useState("");
    const [invoice, setinvoice] = useState([userTemp]);
    const [allemployeelist, setallemployeelist] = useState([]);
    const [cgst, setCgst] = useState(9);
    const [sgst, setSgst] = useState(9);
    const [totalGst, setTotalGst] = useState(0);
    const [discountedTotal, setDiscountTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [advancepayment, setAdvancepayment] = useState(0);
    const [duepayment, setDuepayment] = useState(0);

    const addItemrow = () => {
        setinvoice([...invoice, userTemp]);
    };

    const deleteRow = (index) => {
        const updatedUsers = [...invoice];
        updatedUsers.splice(index, 1);
        setinvoice(updatedUsers);
    };

    const Showdate = new Date();
    const ShowTodaydate = `${Showdate.getFullYear()}-${Showdate.getMonth() + 1}-${Showdate.getDate()}`;
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [client, setClient] = useState([]);
    const [activity, setActivity] = useState([]);
    const [project, setProject] = useState([]);

    const onChange = (e, index) => {
        const { name, value } = e.target;
        const updatedUser = [...invoice];
        updatedUser[index] = { ...updatedUser[index], [name]: value };

        if (name === "quantity" || name === "price") {
            const quantity = parseFloat(updatedUser[index].quantity) || 0;
            const price = parseFloat(updatedUser[index].price) || 0;
            updatedUser[index].totalcost = (quantity * price).toFixed(2);
        }

        setinvoice(updatedUser);
    };
    const validationSchema = Yup.object({
        projectcode: Yup.string().required('Project is required'), 
    });
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setValues
    } = useFormik({
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                 
                const isAnyQuantityZero = invoice.some(
                    (user) => Number(user.quantity) <= 0
                  );
                  const isAnyQuantityEmpty = invoice.some(
                    (user) => user.quantity === ""
                  );
                  if (isAnyQuantityZero || isAnyQuantityEmpty) {
                    alert("Quantity cannot be empty or zero or negative.");
                    return;
                  }
                  const isAnyPriceZero = invoice.some(
                    (user) => Number(user.price) <= 0
                  );
                  const isAnyPriceEmpty = invoice.some(
                    (user) => user.price === ""
                  );
                  if (isAnyPriceZero || isAnyPriceEmpty) {
                    alert("Unit Price cannot be empty or zero or negative.");
                    return;
                  }
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("currentDate", JSON.stringify(currentDate));
                data.append("invoice", JSON.stringify(invoice));
                data.append("companycode", padNumber(companycode, 5));
                data.append("subTotal", JSON.stringify(subTotal));
                data.append("discount", JSON.stringify(discount));
                data.append("discountedTotal", JSON.stringify(discountedTotal));
                data.append("totalAmount", JSON.stringify(totalAmount));
                data.append("cgst", JSON.stringify(cgst));
                data.append("sgst", JSON.stringify(sgst));
                data.append("totalGst", JSON.stringify(totalGst));
                data.append("advancepayment", JSON.stringify(advancepayment));
                data.append("duepayment", JSON.stringify(duepayment));
                data.append("userid", userid);
                if (selectedCustomer) {
                    data.append("client", selectedCustomer.customerid); // Send selected customer ID
                }
                console.log(JSON.stringify(values))
                console.log(JSON.stringify(invoice))
                const response = await axios.post("/newinvoice", data, {
                    "Content-Type": "multipart/form-data",
                });

                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            } catch (err) {
                setSuccess(false);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            }
            action.resetForm();
            setinvoice([userTemp]);
            setDiscount(0);
            setDiscountTotal(0);
            setCgst(9);
            setSgst(9);
            setSubTotal(0);
            setTotalAmount(0);
            setSelectedCustomer(null);
            setProject([])
        },
    });


    const LoadClient = async () => {
        try {
            const res = await axios.get("/customerget");
            const customers = res.data.map(customer => ({
                customerid: customer.customerid,
                name: customer.name,
                address: `${customer.companyname},${customer.address},${customer.state}, ${customer.zipcode}`,
            }));
            setClient(customers);
            console.log(res.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const loadActivity = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const res = await axios.get('/getactivity', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                setActivity(res.data);
            }
        } catch (error) {
            console.error('Error loading activities:', error);
        }
    };

    const loadProject = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const res = await axios.get('/getproject', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                console.log(res.data)
                setProject(res.data);
            }
        } catch (error) {
            console.error('Error loading activities:', error);
        }
    };

    useEffect(() => {
        LoadClient();
        loadActivity();
        loadProject();
    }, [companycode, userrole]);
    useEffect(() => {
        //subtotal
        const newSubTotal = invoice.reduce((acc, item) => acc + parseFloat(item.totalcost || 0), 0);
        //discouted subtotal
        const discountedTotal = newSubTotal * (1 - discount / 100);
        //discout amount
        const discountamount = newSubTotal - discountedTotal;
        //total gst
        const newTotalGst = (discountedTotal * cgst / 100) + (discountedTotal * sgst / 100);
        //total amout
        const newTotalAmount = discountedTotal + newTotalGst;
        const newDuepayment = newTotalAmount - advancepayment
        setDiscountTotal(discountamount.toFixed(2))
        setSubTotal(newSubTotal.toFixed(2));
        setTotalGst(newTotalGst.toFixed(2));
        setTotalAmount(newTotalAmount.toFixed(2));
        setDuepayment(newDuepayment.toFixed(2))
    }, [invoice, discount, cgst, sgst, advancepayment]);

    const renderLabel = (label, isRequired) => (
        <span>
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </span>
    );

    const onSearchItems = (searchTerm) => {
        setSearchValues(searchTerm);
        setValues({
            ...values,
            companyCodes: searchTerm.companycode,
        });
    };
    const handleCustomerChange = (event, value) => {
        setSelectedCustomer(value);
        if (value) {
            // Assuming the customer has an address field
            values.address = value.address;
        } else {
            values.address = "";
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                {success && (
                    <Snackbar
                        open={success}
                        autoHideDuration={3000} // 3 seconds
                        message="Form submitted successfully!"
                    />
                )}
                {error && (
                    <Snackbar
                        open={error}
                        autoHideDuration={3000} // 3 seconds
                        message="Error submitting form. Please try again later."
                    />
                )}
                <Box m="20px">
                    <Header title="Invoice" />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {userrole === "Superadmin" && companycode === 1 ? (
                            <FormControl
                                fullWidth
                                variant="filled"
                                sx={{ gridColumn: "span 2" }}
                            >
                                <CompanycodeSearch onSearchItem={onSearchItems} defaultCompanyCode={padNumber(companycode, 5)} />
                            </FormControl>
                        ) : (
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company Code"
                                name="companyCodes"
                                id="companyCodes"
                                value={padNumber(companycode, 5)}
                                sx={{ gridColumn: "span 2" }}
                            />
                        )}
                        {/* <Autocomplete
                            fullWidth
                            disablePortal
                            id="activity-autocomplete"
                            options={project}
                            getOptionLabel={(option) => option.projectname || ""}
                            onChange={(e, newValue) => {
                                setValues({
                                    ...values,
                                    projectcode: newValue ? newValue.projectcode : "",
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Project"
                                    name="Project"
                                    variant="outlined"
                                    error={touched.projectcode && Boolean(errors.projectcode)}
                                    helperText={touched.projectcode && errors.projectcode}
                                />
                            )}
                        /> */}
                         <TextField
                            fullWidth
                            label={renderLabel("Quotation Heading", true)}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            name="projectcode"
                            id="projectcode"
                            multiline

                            value={values.projectcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.projectcode && !!errors.projectcode}
                            helperText={touched.projectcode && errors.projectcode}
                        />
                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Autocomplete
                            fullWidth
                            variant="filled"
                            disablePortal
                            id="search"
                            options={client}
                            getOptionLabel={(option) => option.name}
                            sx={{ gridColumn: "span 2" }}
                            renderInput={(params) => <TextField {...params} label={renderLabel("Customer Name", true)} />}
                            onChange={handleCustomerChange}
                            value={selectedCustomer}
                        />
                        <TextField
                            fullWidth
                            label={renderLabel("Delivery Address", true)}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            name="address"
                            id="address"
                            multiline

                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                        />
                    </Box>
                    <Box mt="20px" style={{ display: "flex", flexDirection: "column" }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl No</TableCell>
                                        <TableCell style={{ width: "220px" }}>{renderLabel("Activity", true)}</TableCell>
                                        <TableCell>{renderLabel("Quantity",true)}</TableCell>
                                        <TableCell>{renderLabel("Unit Price",true)}</TableCell>
                                        <TableCell>TotalCost</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoice.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <Autocomplete
                                                    fullWidth
                                                    variant="filled"
                                                    disablePortal
                                                    id="activity-autocomplete"
                                                    options={activity}
                                                    value={activity.find(act => act.activitycode === user.activitycode) || null}
                                                    getOptionLabel={(option) => option.activityname || ""}
                                                    onChange={(e, newValue) => {
                                                        const updatedUser = [...invoice];
                                                        updatedUser[index] = {
                                                            ...updatedUser[index],
                                                            activitycode: newValue?.activitycode || ""
                                                        };
                                                        setinvoice(updatedUser);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Activity"
                                                            name="activity"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="quantity"
                                                    onChange={(e) => onChange(e, index)}
                                                    value={user.quantity}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="price"
                                                    onChange={(e) => onChange(e, index)}
                                                    value={user.price}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="totalcost"
                                                    value={user.totalcost}
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => deleteRow(index)}
                                                    style={{ color: "white", backgroundColor: "red" }}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <br />
                    <Button color="secondary"
                        variant="contained" onClick={addItemrow}>{<AddIcon />} <b>Add Row</b></Button>
                    <br />
                    <br />
                    <div>
                        <TextField
                            label="Sub Total"
                            id="SubTotal"
                            variant="outlined"
                            value={subTotal}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Discount%"
                            id="discount"
                            variant="outlined"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                        />
                        <TextField
                            label="Discount Amount"
                            id="discountamount"
                            variant="outlined"
                            value={discountedTotal}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="CGST%"
                            id="cgst"
                            name="cgst"
                            value={cgst}
                            onChange={(e) => setCgst(e.target.value)}
                        />
                        <TextField
                            label="SGST%"
                            id="sgst"
                            name="sgst"
                            value={sgst}
                            onChange={(e) => setSgst(e.target.value)}
                        />
                    </div>
                    <br />
                    <div>

                        <TextField
                            label="Total GST"
                            id="TotalGst"
                            variant="outlined"
                            value={totalGst}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Total Amount"
                            id="TotalAmount"
                            variant="outlined"
                            value={totalAmount}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Advance Payment"
                            id="advancepayment"
                            variant="outlined"
                            value={advancepayment}
                            onChange={(e) => setAdvancepayment(e.target.value)}
                        />
                        <TextField
                            label="Due Payment"
                            id="duepayment"
                            variant="outlined"
                            value={duepayment}
                            InputProps={{ readOnly: true }}
                        />
                    </div>
                    <Box display="flex" justifyContent="initial" mt="20px">
                        {/* <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                id="draft"
                                onClick={() => {
                                    handleChange({
                                        target: { name: "orderstage", value: "Draft" },
                                    });
                                }}
                            >
                                Save As Draft
                            </Button>
                        </Box> */}
                        <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Box>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>
                    </Box>
                    <br />
                </Box>
            </form>
        </>
    );
};

export default Invoice;
