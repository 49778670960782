import React, { useState, useEffect } from "react";
import {
    TextField,
    Grid,
    Typography,
    Divider,
    Box,
    Snackbar,
    Button,
    Autocomplete,
    MenuItem,
    InputLabel,
    Select,
    FormControl
}
    from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/auth";

const CostperHour = () => {
    const {
        userid,
        userrole,
        username,
        companycode,
        token
    } = useUser();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    const padNumber = (number, length) => {
        let str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    };
    const initialValues = {
        costperhr: "",
        companyCodes: userrole === "Superadmin" && companycode === 1 ? "" : companycode,
    };

    const VendorSchema = yup.object().shape({

    });
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [companyCodes, setCompanyCodes] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [employee, setEmployee] = useState([]);
    const handleSupplierChange = (event, value) => {
        setSelectedSupplier(value);
    };
    const loadEmployee = async () => {
        try {
            console.log(companycode);
            if (companycode !== undefined && userrole !== undefined) {
                console.log(`Fetching with companycode: ${companycode} and userrole: ${userrole}`);
                const res = await axios.get('/getUsers', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                console.log('Response:', res.data);
                const Employee = res.data.map(employee => ({
                    userid: employee.userid,
                    firstname: employee.firstname,
                }));
    
                setEmployee(Employee);
                console.log('Employees set:', Employee);
            }
        } catch (error) {
            console.error('Error loading employees:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get("/getCompanyRegistrations");
            setData(response.data);
            setFilteredData(response.data);
            const formattedCodes = response.data.map((company) => company.companycode);
            setCompanyCodes(formattedCodes);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        loadEmployee();
        fetchData();
    }, [companycode,userrole]);
    let Showdate = new Date();
    let ShowTodaydate =
      Showdate.getFullYear() +
      "-" +
      (Showdate.getMonth() + 1) +
      "-" +
      Showdate.getDate();
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
    } = useFormik({
        initialValues,
        validationSchema: VendorSchema,
        onSubmit: async (values, action) => {
            try {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("currentDate", JSON.stringify(currentDate));
                data.append("userid", userid);
                data.append("companycode", padNumber(companycode, 5));
                if (selectedSupplier) {
                    data.append("employee", selectedSupplier.userid); // Send selected customer ID
                  }
                console.log(selectedSupplier.userid);
                console.log(JSON.stringify(values))
                console.log(padNumber(companycode, 5))
                const response = await axios.post("/addcost", data, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                }
            } catch (err) {
                setSuccess(false);
                setError(true);
            }
            action.resetForm();

        },
    });
  
    const renderLabel = (label, isRequired) => (
        <span>
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </span>
    );
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccess(false);
        setError(false);
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box m="20px">
                    <h3>Employee Cost</h3>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    />
                    {success && (
                <Snackbar
                    open={success}
                    autoHideDuration={30000} // 60 seconds
                    onClose={handleClose}
                    message="Form submitted successfully!"
                />
            )}
            {error && (
                <Snackbar
                    open={error}
                    autoHideDuration={3000} // 60 seconds
                    onClose={handleClose}
                    message="Error submitting form. Please try again later."
                />
            )}
                    <Box>
                  {userrole === "Superadmin" && companycode === 1 ? (
                        <FormControl
                            fullWidth
                            variant="filled"
                            sx={{ gridColumn: "span 2" }}
                        >
                            <InputLabel id="companyCode-label">Company Code</InputLabel>
                            <Select
                                labelId="companyCode-label"
                                id="companyCodes"
                                onChange={handleChange}
                                value={values.companyCodes}
                                name="companyCodes"
                                label="Company Code"
                            >
                                <MenuItem value="">Select Company Code</MenuItem>
                                {companyCodes.map((code, index) => (
                                    <MenuItem
                                        key={index}
                                        value={code}
                                    >
                                        {code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Company Code"
                            value={padNumber(companycode, 5)}
                            name="companyCodes"
                            sx={{ gridColumn: "span 2" }}
                        />
                    )}
                   </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                fullWidth
                                variant="filled"
                                disablePortal
                                id="search"
                                options={employee}
                                getOptionLabel={(option) => option.firstname} // Correct the property name if it's not 'firstname'
                                sx={{ gridColumn: "span 2" }}
                                renderInput={(params) => (
                                    <TextField {...params} label={renderLabel("Employee Name", true)} variant="filled" />
                                )}
                                onChange={handleSupplierChange}
                                value={selectedSupplier}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                variant="filled"
                                label={renderLabel("Cost/Hour", true)}
                                name="costperhr"
                                value={values.costperhr}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.costperhr && Boolean(errors.costperhr)}
                                helperText={touched.costperhr && errors.costperhr}
                                sx={{ gridColumn: "span 2", mb: 2 }}
                            />
                        </Grid>
                    </Grid>
                   
                 <Box display="flex" justifyContent="initial" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  SUBMIT
                </Button>
              </Box>
                </Box>
             
            </form>
        </>
    );
};

export default CostperHour;
