import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableCell,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import Header from "../../../assets/charts/Header";
import { NavLink } from "react-router-dom";

const AllEmployeeListViewLeaves = () => {
  const { id,userid, token } = useUser();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(11);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const loadEmployeeLeave = async () => {
    try {
      const res = await axios.get("/AllEmployeeListView", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
    } catch (error) {
      console.error("Error loading employee leave data", error);
    }
  };

  useEffect(() => {
    loadEmployeeLeave();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { field: 'empleavehistoryid', headerName: 'Employee Id', flex: 0.2 },
    {
      field: 'employeename',
      headerName: 'Employee Name',
      flex: 0.2,
      renderCell: (params) => (
        <NavLink
          style={{ textDecoration: "none" }}
          to={`/allemployeeleaveslistview/${params.row.empleavehistoryid}`}
          className="addlink"
        >
          {params.value}
        </NavLink>
      ),
    },
    { field: 'leavetype', headerName: 'Leave Type', flex: 0.2 },
    { field: 'leavefrom', headerName: 'Leave From', flex: 0.2 },
    { field: 'leaveto', headerName: 'Leave To', flex: 0.2 },
    { field: 'description', headerName: 'Leave Description', flex: 0.2 },
    {
      field: 'leavestage',
      headerName: 'Leave Stage',
      flex: 0.2 ,
      renderCell: (params) => {
        let fontColor;
        if (params.value === "Approved") {
          fontColor = "#009700";
        } else if (params.value === "Rejected") {
          fontColor = "red";
        } else if (params.value === "Pending Approval") {
          fontColor = "blue";
        } else {
          fontColor = "inherit";
        }
        return (
          <TableCell style={{ color: fontColor }}>
            {params.value}
          </TableCell>
        );
      },
    },
    { field: 'companycode', headerName: 'Company Code', flex: 0.2 },
    { field: 'status', headerName: 'Status', flex: 0.2 },
  ];

  const rows = data.map((row, index) => ({ id: index, ...row }));

  return (
    <Box  m="10px">
      <Helmet>
        <title>BBS ERP</title>
      </Helmet>
      <Header title="All Employee Leaves" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.empleavehistoryid}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default AllEmployeeListViewLeaves;


