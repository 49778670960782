import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import { IoCheckmarkSharp } from "react-icons/io5";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box
} from "@mui/material";

const LeaveEntry = () => {
  const { companycode, token, userrole } = useUser();
  const [data, setData] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  const loadEmployee = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getUsers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });

        if (response && response.data) {
          setData(response.data);

          const leaveData = await Promise.all(
            response.data.map(async (employee) => {
              const leaveResponse = await axios.get(`/getleavedata/${employee.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              return leaveResponse.data;
            })
          );

          const initialCheckboxes = leaveData.map((leave) =>
            leave.map((value) => parseInt(value, 10) === 1)
          );

          setCheckboxes(initialCheckboxes);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    loadEmployee();
  }, [companycode, userrole, token]);

  const handleCheckboxChange = (employeeIndex, monthIndex) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((employee, i) =>
        i === employeeIndex
          ? employee.map((checked, j) => (j === monthIndex ? !checked : checked))
          : employee
      )
    );
  };

  const handleSubmit = (id, firstname, totalLeave, monthsData) => {
    const isConfirmed = window.confirm("Do you want to submit total leave?");
    if (isConfirmed) {
      try {
        axios.post(
          `/SubmitTotalLeave`,
          { id, firstname, totalLeave, monthsData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        alert(`Something Error`);
        console.error("Error submitting total leave:", error);
      }
    }
  };

  const handleSubmitAll = () => {
    const isConfirmed = window.confirm("Do you want to submit total leave for all employees?");
    data.forEach((employee, employeeIndex) => {
      const totalLeave = checkboxes[employeeIndex]?.filter(Boolean).length * 2;
      if (totalLeave !== undefined) {
        const monthsData = checkboxes[employeeIndex].map((checked) => (checked ? 1 : 0));
        if (isConfirmed) {
          try {
            axios.post(
              `/SubmitTotalLeave`,
              { id: employee.id, firstname: employee.firstname, totalLeave, monthsData },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } catch (error) {
            console.error("Error submitting total leave:", error);
          }
        }
      }
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    const newMonth = currentDate.getMonth();
    if (newMonth !== currentMonth) {
      setCurrentMonth(newMonth);
    }
  }, [currentMonth]);

  return (
    <>
      <Helmet>
        <title>BBS ERP</title>
      </Helmet>
      <Box m="20px">
        <TableContainer component={Paper}>
          <Table aria-label="leave entry table">
            <TableHead>
              <TableRow>
                <TableCell>EmpId</TableCell>
                <TableCell>Name</TableCell>
                {[...Array(12).keys()].map((month) => (
                  <TableCell key={month}>{new Date(0, month).toLocaleString('default', { month: 'short' })}</TableCell>
                ))}
                <TableCell>Total Leave</TableCell>
                <TableCell>Submit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((employee, employeeIndex) => (
                <TableRow key={employeeIndex}>
                  <TableCell>{employee.id}</TableCell>
                  <TableCell>{employee.firstname}</TableCell>
                  {checkboxes[employeeIndex] && checkboxes[employeeIndex].map((isChecked, monthIndex) => (
                    <TableCell key={monthIndex}>
                      <Checkbox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(employeeIndex, monthIndex)}
                      />
                    </TableCell>
                  ))}
                  <TableCell>{checkboxes[employeeIndex]?.filter(Boolean).length * 2}</TableCell>
                  <TableCell>
                    <IoCheckmarkSharp
                      onClick={() => {
                        const totalLeave = checkboxes[employeeIndex]?.filter(Boolean).length * 2;
                        const monthsData = checkboxes[employeeIndex]?.map((checked) => (checked ? 1 : 0));
                        handleSubmit(employee.id, employee.firstname, totalLeave, monthsData);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <Button  variant="success"
          className="mx-3" 
          onClick={handleSubmitAll}>Submit All</Button>
      </Box>
    </>
  );
};

export default LeaveEntry;
