import React, { useState, useEffect } from "react";
import Header from "../../assets/charts/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "../../utils/axios";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/auth";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from 'yup';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Box,
    FormControl,
    Autocomplete,
    MenuItem,
    InputLabel,
    Select,
    Snackbar,
} from "@mui/material";

const AddQuotation = () => {
    const { userid, userrole, companycode, token } = useUser();
    const initialValues = {
        companycode: "",
        projectcode: "",
        projectname: "",
        address:"",
        name:"",
        subTotal:"",
        discount:"",
        discountedamount:"",
        totalGst:"",
        totalAmount:"",
        cgst:"",
        sgst:"",
        quotationno:""
    };
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const padNumber = (number, length) => {
        let str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    };
    const quotations = {
        activityname:"",
        totalHours: "",
        averageCostPerHr: "",
    };
    const [quotationdetails, setQuotationdetails] = useState([quotations]);
    const[discount,setDiscount]= useState(0);
    const [cgst, setCgst] = useState(9);
    const [sgst, setSgst] = useState(9);
    const [totalGst, setTotalGst] = useState(0.0);
    const [subTotal, setSubTotal] = useState(0.0);
    const [totalAmount, setTotalAmount] = useState(0.0);
    const [discountedTotal, setDiscountTotal] = useState(0);
    const Showdate = new Date();
    const ShowTodaydate = `${Showdate.getFullYear()}-${Showdate.getMonth() + 1}-${Showdate.getDate()}`;
    const [currentDate, setCurrentDate] = useState(ShowTodaydate);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [quotation, setQuotation] = useState([]);
    const validationSchema = Yup.object({
        quotationno: Yup.string().required('Quotation is required'),
    });
    
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setValues
    } = useFormik({
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                const data = new FormData();
                data.append("document", JSON.stringify(values));
                data.append("currentDate", JSON.stringify(currentDate));
                data.append("quotationdetails", JSON.stringify(quotationdetails));
                data.append("companycode", padNumber(companycode, 5));
                data.append("subTotal", JSON.stringify(subTotal));
                data.append("totalAmount", JSON.stringify(totalAmount));
                data.append("cgst", JSON.stringify(cgst));
                data.append("sgst", JSON.stringify(sgst));
                data.append("discount", JSON.stringify(discount));
                data.append("totalGst", JSON.stringify(totalGst));
                data.append("userid", userid);
                console.log( JSON.stringify(values))
                console.log( JSON.stringify(quotationdetails))
                const response = await axios.post(`/quotationpost`, data, {
                    "Content-Type": "multipart/form-data",
                });

                if (response.status === 200) {
                    setSuccess(true);
                    setError(false);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            } catch (err) {
                setSuccess(false);
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            }
            action.resetForm();
            setQuotationdetails([quotations]);
        },
    });
  
    const handleQuotationChange = async (event, newValue) => {
        const selectedQuotation = newValue;
        if (selectedQuotation) {
            fetchData(selectedQuotation.quotationno);
        }
    };

    const loadQuotation = async () => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const res = await axios.get('/allquotationplanning', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                setQuotation(res.data);
            }
        } catch (error) {
            console.error('Error loading employees:', error);
        }
    };

    const fetchData = async (quotationNo) => {
        try {
            if (companycode !== undefined && userrole !== undefined) {
                const response = await axios.get(`/costquotation/${quotationNo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        companycode: companycode,
                        userrole: userrole,
                    },
                });
                if (response && response.data && response.data.length > 0) {
                    setQuotationdetails(response.data);
                    setValues(response.data[0]);
                    setSelectedCustomer({
                        customerid: response.data[0].customerid,
                        name: response.data[0].name
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching quotation data:', error);
        }
    };

    useEffect(() => {
        loadQuotation();
    }, [companycode, userrole]);


    const calculateTotals = () => {
        // Calculate subTotal
        const newSubTotal = quotationdetails.reduce((sum, item) => {
            return sum + parseFloat(item.averageCostPerHr); // Ensure item.averageCostPerHr is parsed as float if needed
        }, 0);
        setSubTotal(newSubTotal);
    
        // Calculate total GST
        const newTotalGst = (newSubTotal * cgst / 100) + (newSubTotal * sgst / 100);
        setTotalGst(newTotalGst);
    
        // Calculate total amount including GST and subtracting discount
        const newTotalAmount = newSubTotal + newTotalGst - discount;
        setTotalAmount(newTotalAmount);
    };
    

    const renderLabel = (label, isRequired) => (
        <span>
            {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </span>
    );

    return (
        <>
            <form onSubmit={handleSubmit}>
                {success && (
                    <Snackbar
                        open={success}
                        autoHideDuration={30000} // 60 seconds
                        message="Form submitted successfully!"
                    />
                )}
                {error && (
                    <Snackbar
                        open={error}
                        autoHideDuration={3000} // 60 seconds
                        message="Error submitting form. Please try again later."
                    />
                )}
                <Box m="20px">
                    <Header title="Quotation" />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Autocomplete
                            fullWidth
                            options={quotation}
                            getOptionLabel={(option) => option.quotationno}
                            value={values.quotation}
                            onChange={handleQuotationChange}
                            renderInput={(params) =>
                           <TextField {...params} label={renderLabel("Select Quotation", true)}
                            error={touched.quotationno && Boolean(errors.quotationno)}
                            helperText={touched.quotationno && errors.quotationno}
                           />}
                        />
                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            fullWidth

                            type="text"
                            label="Company Code"
                            name="companycode"
                            id="companycode"
                            value={padNumber(values.companycode, 5)}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            fullWidth

                            type="text"
                            label="Quotation Heading"
                            name="projectcode"
                            id="projectcode"
                            value={values.projectcode}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            fullWidth

                            type="text"
                            label="Customer Name"
                            name="name"
                            id="name"
                            value={values.name}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            fullWidth

                            label={renderLabel("Delivery Address", true)}
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            name="address"
                            id="address"
                            InputProps={{ readOnly: true }}
                            multiline
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                        />
                    </Box>
                 
                    <Box mt="20px" style={{ display: "flex", flexDirection: "column" }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sl No</TableCell>
                                        <TableCell>Activity</TableCell>
                                        <TableCell>Hour</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quotationdetails.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="activityname"
                                                    value={user.activityname}
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="totalHours"
                                                    value={user.totalHours}
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="text"
                                                    name="averageCostPerHr"
                                                    value={user.averageCostPerHr}
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </TableCell>
                                           
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Sub Total"
                            name="subTotal"
                            id="subTotal"

                            value={values.subTotal}
                            InputProps={{ readOnly: true }}
                            sx={{ gridColumn: "span 2" }}
                        />
                       <TextField
                            label="Discount%"
                            name="discount"
                            id="discount"

                            value={values.discount}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                         <TextField
                            label="Discount Amount"
                            name="discountedamount"
                            id="discountedamount"

                            value={values.discountedamount}
                            InputProps={{ readOnly: true }}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            label="CGST%"
                            id="cgst"
                            name="cgst"
                            value={values.cgst}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                        </Box>
                        <br/>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="SGST%"
                            id="sgst"
                            name="sgst"
                            value={values.sgst}
                            sx={{ gridColumn: "span 2" }}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Total GST"
                            name="totalGst"
                            id="totalGst"

                            value={values.totalGst}
                            InputProps={{ readOnly: true }}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            label="Total Amount"
                            name="totalAmount"
                            id="totalAmount"

                            value={values.totalAmount}
                            InputProps={{ readOnly: true }}
                            sx={{ gridColumn: "span 2" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="initial" mt="20px">
                        {/* <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                id="draft"
                                onClick={() => {
                                    handleChange({
                                        target: { name: "orderstage", value: "Draft" },
                                    });
                                }}
                            >
                                Save As Draft
                            </Button>
                        </Box> */}
                        <Box mr={2}>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Box>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="button"
                            onClick={resetForm}
                        >
                            Reset
                        </Button>
                    </Box>
                    <br />
                </Box>
            </form>
        </>
    );
};

export default AddQuotation;
