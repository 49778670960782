// import React, { useState,useEffect } from "react";
// import { Helmet } from "react-helmet";
// import { useFormik } from "formik";
// import axios from "../../utils/axios"; // Ensure axios is imported properly
// import { useUser } from "../../contexts/auth";
// import Header from "../../assets/charts/Header";
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     TextField,
//     Button,
//     Box,
//     FormControl,
//     Autocomplete,
//     Snackbar,
// } from "@mui/material";
// import CompanycodeSearch from "../../common/CompanycodeSearch";

// const initialValues = {
//   holidayname: "",
//   holidaydate: "",
//   entrydate: "",
// };

// const AddHoliday = () => {
//   const { username,userid, userrole, companycode, token } = useUser();
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [searchValues, setSearchValues] = useState("");
//   const Showdate = new Date();
//   const ShowTodaydate = `${Showdate.getFullYear()}-${Showdate.getMonth() + 1}-${Showdate.getDate()}`;
//   const [currentDate, setCurrentDate] = useState(ShowTodaydate);
//   const padNumber = (number, length) => {
//     let str = '' + number;
//     while (str.length < length) {
//         str = '0' + str;
//     }
//     return str;
   
// };
//   const { values, errors, touched, handleChange, handleBlur, handleSubmit,resetForm, setValues } =
//     useFormik({
//       initialValues,
//       onSubmit: async (values, { resetForm }) => {
//         try {
//           const currentDate = new Date().toISOString().split("T")[0];
//           const data = new FormData();
//           data.append("document", JSON.stringify(values));
//           data.append("companycode", padNumber(companycode, 5));
//           data.append("userid", userid);
//           data.append("currentDate", JSON.stringify(currentDate));
//           // const valuesWithUser = {
//           //   ...values,
//           //   name: username,
//           // };
// console.log(JSON.stringify(values))
// console.log(userid)
// console.log( JSON.stringify(currentDate))
// console.log( padNumber(companycode, 5))
//           const response = await axios.post("/addholiday", data, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           });

//           if (response.status === 200) {
//             setSnackbarSeverity("success");
//             setSnackbarMessage("Holiday added successfully.");
//           } else {
//             setSnackbarSeverity("error");
//             setSnackbarMessage("Failed to add holiday. Please try again.");
//           }
//         } catch (error) {
//           console.error("Error adding holiday:", error);
//           setSnackbarSeverity("error");
//           setSnackbarMessage("Failed to add holiday. Please try again.");
//         } finally {
//           setSnackbarOpen(true);
//           resetForm();
//         }
//       },
//     });

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const getCurrentDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = `${today.getMonth() + 1}`.padStart(2, "0");
//     const day = `${today.getDate()}`.padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   const onSearchItems = (searchTerm) => {
//     setSearchValues(searchTerm);
//     setValues({
//         ...values,
//         companyCodes: searchTerm.companycode,
//     });
// };
//   return (
//     <>
//       <Helmet>
//         <title>Add Holiday</title>
//       </Helmet>
//       <div className="form-container">
//         <form onSubmit={handleSubmit}>
//         <Box m="20px">
//         <Header title="Add Holiday" />
//         <Box sx={{ display: 'flex', gap: 2 }}>
//           {/* <TextField
//             id="entrydate"
//             name="entrydate"
//             label="Entry Date"
//             type="date"
//             variant="outlined"
//             fullWidth
//             value={values.entrydate || getCurrentDate()}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             error={errors.entrydate && touched.entrydate}
//             helperText={errors.entrydate && touched.entrydate && errors.entrydate}
//             required
//           /> */}
//            {userrole === "Superadmin" && companycode === 1 ? (
//                             <FormControl
//                                 fullWidth
//                                 variant="filled"
//                                 sx={{ gridColumn: "span 2" }}
//                             >
//                                 <CompanycodeSearch onSearchItem={onSearchItems} />
//                             </FormControl>
//                         ) : (
//                             <TextField
//                                 fullWidth
//                                 variant="filled"
//                                 type="text"
//                                 label="Company Code"
//                                 name="companyCodes"
//                                 id="companyCodes"
//                                 value={padNumber(companycode, 5)}
//                                 sx={{ gridColumn: "span 2" }}
//                             />
//                         )}
//            </Box>
//            <br />
//         <Box sx={{ display: 'flex', gap: 2 }}>
//           <TextField
//             id="holidayname"
//             name="holidayname"
//             label="Holiday Name"
//             variant="outlined"
//             fullWidth
//             value={values.holidayname}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             error={errors.holidayname && touched.holidayname}
//             helperText={errors.holidayname && touched.holidayname && errors.holidayname}
//             required
//           />
//           <TextField
//             id="holidaydate"
//             name="holidaydate"
//             label="Holiday Date"
//             type="date"
//             variant="outlined"
//             fullWidth
//             value={values.holidaydate}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             error={errors.holidaydate && touched.holidaydate}
//             helperText={errors.holidaydate && touched.holidaydate && errors.holidaydate}
//             required
//           />
//           </Box>
        
//            <br />
//            <Box display="flex" justifyContent="initial" mt="20px">
//                         <Box mr={2}>
//                             <Button
//                                 color="secondary"
//                                 variant="contained"
//                                 type="submit"
//                             >
//                                 Save
//                             </Button>
//                         </Box>
//                         <Button
//                             color="secondary"
//                             variant="contained"
//                             type="button"
//                             onClick={resetForm}
//                         >
//                             Reset
//                         </Button>
//                     </Box>
//                     <br />
//           </Box>
//         </form>
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleCloseSnackbar}
//           message={snackbarMessage}
//           severity={snackbarSeverity}
//         />
//       </div>
//     </>
//   );
// };

// export default AddHoliday;


import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import Header from "../../assets/charts/Header";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Box,
    FormControl,
    Autocomplete,
    Snackbar,
} from "@mui/material";
import CompanycodeSearch from "../../common/CompanycodeSearch";

const initialValues = {
  holidayname: "",
  holidaydate: "",
  entrydate: "",
};

const AddHoliday = () => {
  const { username, userid, userrole, companycode, token } = useUser();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchValues, setSearchValues] = useState("");
  const Showdate = new Date();
  const ShowTodaydate = `${Showdate.getFullYear()}-${Showdate.getMonth() + 1}-${Showdate.getDate()}`;
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const padNumber = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setValues } =
    useFormik({
      initialValues,
      onSubmit: async (values, { resetForm }) => {
        try {
          const currentDate = new Date().toISOString().split("T")[0];
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("companycode", padNumber(companycode, 5));
          data.append("userid", userid);
          data.append("currentDate", JSON.stringify(currentDate));
          console.log(JSON.stringify(values))
          console.log(userid)
          console.log(JSON.stringify(currentDate))
          console.log(padNumber(companycode, 5))
          const response = await axios.post("/addholiday", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 200) {
            setSnackbarSeverity("success");
            setSnackbarMessage("Holiday added successfully.");
          } else {
            setSnackbarSeverity("error");
            setSnackbarMessage("Failed to add holiday. Please try again.");
          }
        } catch (error) {
          console.error("Error adding holiday:", error);
          setSnackbarSeverity("error");
          setSnackbarMessage("Failed to add holiday. Please try again.");
        } finally {
          setSnackbarOpen(true);
          resetForm();
        }
      },
    });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, "0");
    const day = `${today.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const onSearchItems = (searchTerm) => {
    setSearchValues(searchTerm);
    setValues({
        ...values,
        companyCodes: searchTerm.companycode,
    });
  };

  return (
    <>
      <Helmet>
        <title>Add Holiday</title>
      </Helmet>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Box m="20px">
            <Header title="Add Holiday" />
            <Box sx={{ display: 'flex', gap: 2 }}>
              {userrole === "Superadmin" && companycode === 1 ? (
                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                  <CompanycodeSearch onSearchItem={onSearchItems} defaultCompanyCode={padNumber(companycode, 5)} />
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Company Code"
                  name="companyCodes"
                  id="companyCodes"
                  value={padNumber(companycode, 5)}
                  sx={{ gridColumn: "span 2" }}
                />
              )}
            </Box>
            <br />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                id="holidayname"
                name="holidayname"
                label="Holiday Name"
                variant="outlined"
                fullWidth
                value={values.holidayname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.holidayname && touched.holidayname}
                helperText={errors.holidayname && touched.holidayname && errors.holidayname}
                required
              />
              <TextField
                id="holidaydate"
                name="holidaydate"
                label="Holiday Date"
                type="date"
                variant="outlined"
                fullWidth
                value={values.holidaydate}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.holidaydate && touched.holidaydate}
                helperText={errors.holidaydate && touched.holidaydate && errors.holidaydate}
                required
              />
            </Box>
            <br />
            <Box display="flex" justifyContent="initial" mt="20px">
              <Box mr={2}>
                <Button color="secondary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
              <Button color="secondary" variant="contained" type="button" onClick={resetForm}>
                Reset
              </Button>
            </Box>
            <br />
          </Box>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </div>
    </>
  );
};

export default AddHoliday;
