import React, { useEffect, useState } from "react";
import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { NavLink} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/auth";
import Header from "../../assets/charts/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material/styles";
const ListViewHoliday = () => {
    const { userid, userrole, companycode, token,username, } = useUser();
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteHolidayId, setDeleteHolidayId] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const padNumber = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }
  const loadHolidays = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
      const response = await axios.get("/getholidays", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companycode: companycode,
          userrole: userrole,
      },
      });
      setData(response.data);
    } 
  }catch (error) {
      console.error("Error loading holidays:", error);
    }
  };

  const deleteHoliday = async () => {
    try {
      const response = await axios.post(`/deleteholiday/${deleteHolidayId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        loadHolidays();
      } else {
        console.error("Failed to delete holiday");
      }
    } catch (error) {
      console.error("Error deleting holiday:", error);
    } finally {
      setOpenDialog(false);
    }
  };

  const editHoliday = (holidayId) => {
    // history.push(`/editholiday/${holidayId}/edit`);
  };

  useEffect(() => {
      loadHolidays();
    }, [companycode, userrole]);

  const handleDeleteConfirm = () => {
    deleteHoliday();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns = [
    { field: "holidaydate", headerName: "Holiday Date", flex: 1 },
    { field: "holidayname", headerName: "Holiday Day Name", flex: 1 },
    {
      field: "entryby",
      headerName: "Entry By",
      flex: 1,
      hide: userRole !== "Superadmin",
    },
    {
      field: "entrydate",
      headerName: "Entry Date",
      flex: 1,
      hide: userRole !== "Superadmin",
    },
    { field: "companycode", headerName: "Company Code", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      hide: userRole !== "Superadmin",
      renderCell: (params) => (
    <>
       <IconButton color="secondary" >
          <NavLink to={`/editholiday/${params.row.holidayid}/edit`}>
          < EditIcon/>
        </NavLink>
          </IconButton>
    </>
        
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.5,
      hide: userRole !== "Superadmin",
      renderCell: (params) => (
        <IconButton onClick={() => {
          setDeleteHolidayId(params.row.holidayid);
          setOpenDialog(true);
        }}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box  m="20px">
      <Helmet>
        <title>BBS ERP Holiday List</title>
      </Helmet>
      <Header title="Holiday List" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      <DataGrid
        rows={data}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        getRowId={(row) => row.holidayid}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        slots={{ toolbar: GridToolbar }}
      />
       </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this holiday?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ListViewHoliday;
