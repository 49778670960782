import React, { useRef } from 'react';
import { Grid, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

// const PrintComponent = ({ children, commonContent }) => {
//     const componentRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//        // onAfterPrint: props.handleClose,
//       });

//     return (
//         <Grid container>
//             <Grid item xs={12}>
//                 <Button className={"print-preview-button"} onClick={handlePrint}>Print Report</Button>
                
//             </Grid>
//             <Grid item xs={12} ref={componentRef}>
//                 {/* Display commonContent in the header */}
//                 <table className="print-component">
//                     <thead>
//                         {/* Table headers */}
//                         <tr>
//                             {/* Display commonContent in the table header */}
//                             <td>{commonContent}</td>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>{children}</td>
//                         </tr>
//                     </tbody>
//                     <tfoot className="table-footer">
//                         <tr>
//                             <td>{"Page footer"}</td>
//                         </tr>
//                     </tfoot>
//                 </table>
//             </Grid>
//         </Grid>
//     );
// };

// export default PrintComponent;

// import React, { useRef } from 'react';
// import { Grid, Button } from '@mui/material';
// import { useReactToPrint } from 'react-to-print';

// const PrintComponent = ({ children, commonContent }) => {
//     const componentRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => componentRef.current,
//     });

//     return (
//         <Grid container>
//             <Grid item xs={12}>
//                 <Button className="print-preview-button" onClick={handlePrint}>Print Report</Button>
//             </Grid>
//             <Grid item xs={12} ref={componentRef}>
//                 {/* {commonContent}
//                 {children} */}
//                     <table className="print-component">
//                     <thead>
//                         {/* Table headers */}
//                         <tr>
//                             {/* Display commonContent in the table header */}
//                             <td>{commonContent}</td>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>{children}</td>
//                         </tr>
//                     </tbody>
//                     <tfoot className="table-footer">
//                         <tr>
//                             <td>{"Page footer"}</td>
//                         </tr>
//                     </tfoot>
//                 </table>
//             </Grid>
//         </Grid>
//     );
// };

// export default PrintComponent;
const PrintComponent = ({ children, commonContent }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button className="print-preview-button" onClick={handlePrint}>Print Qutation Planing</Button>
            </Grid>
            <Grid item xs={12} ref={componentRef}>
                <table className="print-component">
                    <thead>
                        <tr>
                            <td>{commonContent}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{children}</td>
                        </tr>
                    </tbody>
                    <tfoot className="table-footer">
                        <tr>
                            {/* <td>{"Page footer"}</td> */}
                        </tr>
                    </tfoot>
                </table>
            </Grid>
        </Grid>
    );
};

export default PrintComponent;