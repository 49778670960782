import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "../../utils/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import QuotationFile from "../Prints/QuotationFile";
import Profomainvoice from "../Prints/Profomainvoice";


const AllProformaInvoice = () => {
  const { userid, userrole, companycode, token } = useUser();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const [opened, setOpened] = useState(false);
  const [open, setOpen] = useState(false);
  const [proformainvoicenumber, setPid] = useState();
  const handleClosed = () => {
    setOpened(false);
  };const printForm = async (row) => {
    console.log(row)
     setPid(row.proformainvoicenumber)
     setOpened(true);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companycode !== undefined && userrole !== undefined) {
          const response = await axios.get(`/proformainvoicedetails`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              companycode: companycode,
              userrole: userrole,
            },
          });
          if (response && response.data) {
            setUsers(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, [companycode, userrole]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteUser/${deleteUserId}`);

      if (response && response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== deleteUserId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }

    setOpenDialog(false);
  };

  const columns = [
    {
      field: "proformainvoicenumber",
      headerName: "Proforma Invoice No",
      flex: 0.3,
      // renderCell: (params) => (
      //   <NavLink to={`/qutationplainingdetails/${params.row.proformainvoicenumber}`}>
      //     {params.value}
      //   </NavLink>
      // ),
    },
    {
      field: "invoiceno",
      headerName: "Invoice No",
      flex: 0.3,
    },
    {
      field: "totalamount",
      headerName: "Total Cost",
      flex: 0.3,
    },
    {
      field: "projectcode",
      headerName: "Quatation Heading",
      flex: 0.3,
    },
    {
        field: "",
        headerName: "Action",
        flex: 0.3,
        renderCell: (params) => {
          return(
            <>
          <IconButton color="secondary" onClick={() => printForm(params.row)}>
            <PrintIcon />
          </IconButton>
          </>
        )},
      },
  ];

  return (
    <Box m="20px">
      <Header title="Proforma Invoices" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
     
         <DataGrid
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.idproformainvoice}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this lead?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={opened} onClose={handleClosed} maxWidth="sm" fullWidth>
        <DialogActions>
          <DialogTitle>Modal Title</DialogTitle>
          <Button onClick={handleClosed}>Close</Button>
        </DialogActions>
        <DialogContent>
        <Profomainvoice sId={proformainvoicenumber} handleClose={handleClosed}/>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AllProformaInvoice;
