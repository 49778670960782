import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  Badge,
  Modal,
  Button,
} from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import axios from "../../utils/axios";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate
import { useUser } from "../../contexts/auth";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ListItemButton
      selected={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      component={Link}
      to={to}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default function Sidebar() {
  const theme = useTheme();
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = React.useState(true);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  console.log(userrole,
    username,
    companycode,)
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); // Use useNavigate
  const handleAction = () => {
    // No action needed, simply close the modal
    closeModal();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("/notifications");

      if (!response.data) {
        throw new Error("No data received");
      }

      const data = response.data;
      console.log("Fetched data:", data);
      setNotifications(data.notifications);
      setNewNotifications(
        data.notifications.some((notification) =>
          isNewNotification(notification)
        )
      );
    } catch (error) {
      console.error("Error fetching notifications:", error);

      // Log the entire response to inspect its content
      if (error.response && error.response.data) {
        // console.log("Raw Response:", error.response.data);
      }
    }
  };

  const isNewNotification = (notification) => {
    const notificationDate = new Date(notification.expiredate);
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    return (
      notificationDate.getTime() - currentDate.getTime() < oneDayInMilliseconds
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, []); // Add notifications to the dependency array to log when it changes

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLogout = async () => {
    // Prompt the user to confirm logout
    const confirmed = window.confirm("Do you want to log out?");
    if (confirmed) {
      try {
        // Call the logout API endpoint
        const response = await axios.post("/logout");

        // Check if the logout was successful
        if (response.status === 200) {
          // Remove JWT token from localStorage
          localStorage.removeItem("jwtToken");
          // Redirect the user to the homepage after logout
          navigate("/login");
        }
      } catch (error) {
        // Handle errors, such as token expiration or invalid token
        if (error.response.status === 403) {
          alert("Token Expired. Please login again.");
          localStorage.removeItem("jwtToken");
          navigate("/login");
        } else if (error.response.status === 401) {
          alert("Invalid token. Please login again.");
          localStorage.removeItem("jwtToken");
          navigate("/login");
        } else {
          alert("Error: " + error.response.data.error);
        }
      }
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Box display="flex" justifyContent="space-between" p={2}>
              {/* SEARCH BAR */}
              {/* <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
              >
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchIcon />
                </IconButton>
              </Box> */}

              {/* ICONS */}
              <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )}
                </IconButton>
                <IconButton onClick={openModal}>
                  <Badge
                    badgeContent={notifications.length}
                    color="error"
                    variant="dot"
                  >
                    <NotificationsOutlinedIcon />
                  </Badge>
                </IconButton>
                <IconButton>
                  <SettingsOutlinedIcon />
                </IconButton>
                <IconButton onClick={handleMenuOpen}>
                  <PersonOutlinedIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
              <Modal open={modalOpen} onClose={closeModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    width: 500,
                    borderRadius: 3,
                  }}
                >
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={closeModal}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 5,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h5" mb={2}>
                    Reminders
                  </Typography>
                  <Divider sx={{ mb: 2 }} />{" "}
                  {/* Add a divider for better visual separation */}
                  <List>
                    {notifications.map((notification, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={`${notification.ownername}, Your ${
                            notification.sourcetable
                          } expiry date is on: ${new Date(
                            notification.expiredate
                          ).toDateString()}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                  {/* Add a button for further action */}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" color="primary" onClick={handleAction}>
                      Take Action
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {userrole === "Superadmin" && companycode === 1 && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Establishment
                </Typography>
                <Item
                  title="Add "
                  to="/add-establishment"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="All "
                  to="/all-establishment"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Employee
            </Typography>
            {(userrole === "Superadmin" || userrole === "Manager") && (
            <Item
              title="Add "
              to="/add-user"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
             {(userrole === "Superadmin" || userrole === "Manager") && (
            <Item
              title="All "
              to="/all-user"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
             {userrole === "Superadmin" && (
            <Item
              title="Cost/hr"
              to="/employeecost"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
             {(userrole === "Superadmin" || userrole === "Manager") && (
             <Item
              title="Team Attendance"
              to="#"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
            <Item
              title="Apply Leave"
              to="/applyleave"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             {(userrole === "Superadmin" || userrole === "Manager") && (
            <Item
              title="Team Leave"
              to="/allemployeeleaveslistview"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
             <Item
              title="My Leave"
              to="/employeeleavelist"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> 
             {(userrole === "Superadmin" || userrole === "Manager") && (
              <Item
              title="Leave Entry"
              to="/leaveentry"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />)}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Client
            </Typography>
            <Item
              title="Add"
              to="/client"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All"
              to="/allclient"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Qutation Planing
            </Typography>
            <Item
              title="Add"
              to="/new-qutation"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All"
              to="/all-qutation"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
         
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Qutation
            </Typography>
            <Item
              title="Add"
              to="/addquotation"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All"
              to="/allquotations"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             Invoice
            </Typography>
            <Item
              title="Add"
              to="/addinvoice"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All"
              to="/allinvoices"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Porforma Invoice
            </Typography>
            <Item
              title="Add"
              to="/addproformainvoice"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="All"
              to="/allproformainvoice"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Calendar
            </Typography>
            <Item
              title="Holiday"
              to="/addholiday"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Holiday List"
              to="/listviewholidays"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Typography paragraph>
          {/* Your main content */}
        </Typography>
      </Main>
    </Box>
  );
}
