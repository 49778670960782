import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "../../../utils/axios";
import React, { useState, useEffect } from "react";

import {
  Box,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { tokens } from "../../../theme";
import { mockDataContacts } from "../../../data/mockData";

import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";

//list view for establishment
const Allestablishment = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [establishment, setEstablishment] = useState([]);
  const [deleteEstablishmentId, setDeleteEstablishmentId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);

  //get all the establishment
  useEffect(() => {
    const fetchData = async () => {
      try {
        //api to get all establishments
        const response = await axios.get("/getCompanyRegistrations");
        if (response && response.data) {
          setEstablishment(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, []);

  //to edit
  const handleEdit = (establishmentId) => {
    console.log(establishmentId);
    navigate(`/update-establishment/${establishmentId}`);
  };

  //to delete
  const handleDelete = (establishmentId) => {
    setDeleteEstablishmentId(establishmentId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //this will handle the delete functionality
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `/deleteEstablishment/${deleteEstablishmentId}`
      );

      if (response && response.status === 200) {
        setEstablishment((prevLeads) =>
          prevLeads.filter(
            (establishment) =>
              establishment.establishmentid !== deleteEstablishmentId
          )
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }

    setOpenDialog(false);
  };


  //to display the list
  const columns = [
    { field: "companycode", headerName: "ID", flex: 0.4 },
    {
      field: "establishmentname",
      headerName: "Establishment Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "address1",
      headerName: "Address",
      flex: 1,
    },
    {
      headerName: "Actions",
      flex: 0.5,
      renderCell: (props) => buttonAlign(props),
    },
  ];


  //edit and delete icons
  const buttonAlign = (props) => {
    return (
      <React.Fragment key={`action-${props.row.id}`}>
        <EditIcon
          style={{ marginRight: "10px" }}
          onClick={() => handleEdit(props.row.id)}
        />
        <DeleteIcon onClick={() => handleDelete(props.row.id)} />
      </React.Fragment>
    );
  };

  return (
    <Box m="20px">
      <Header
        title="Establishment List"
        subtitle="List of Registered Establishments..."
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
              <DataGrid
          rows={establishment}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.companycode}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          slots={{ toolbar: GridToolbar }}
        />

      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this lead?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Allestablishment;
