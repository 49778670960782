import * as Yup from "yup";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Header from "../../assets/charts/Header";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";
import CompanycodeSearch from "../../common/CompanycodeSearch";

// Validation Schema using yup
const VendorSchema = Yup.object().shape({
  companyname: Yup.string().required("Company name is required"),
  name: Yup.string().required("Client name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  phoneno: Yup.string().required("Phone number is required"),
  // gstnumber: Yup.string().required("GST number is required"),
  // primarycontact: Yup.string().required("Primary contact is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipcode: Yup.string().required("Zipcode is required"),
 // phonenos: Yup.string().required("Phone number is required"),
 // remarks: Yup.string(),
});

const initialValues = {
  companyname: "",
  name: "",
  email: "",
  phoneno: "",
  gstnumber: "",
  primarycontact: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phonenos: "",
  remarks: "",
  companyCodes: "",
};

const AddClient = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {    
     userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token, } = useUser();
  const navigate = useNavigate();
  const [searchValues, setSearchValues] = useState("");
  const [showTab, setShowTab] = useState(1);
  const [dataUser, setDataUser] = useState([
    {
      firstname: "",
      lastname: "",
      useremail: "",
      workphone: "",
      mobilephone: "",
    },
  ]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [getToken, setToken] = useState();
  const onSearchItems = (searchTerm) => {
    setSearchValues(searchTerm);
    setValues({
        ...values,
        companyCodes: searchTerm.companycode,
    });
};
const padNumber = (number, length) => {
  let str = '' + number;
  while (str.length < length) {
      str = '0' + str;
  }
  return str;
};
  const addUser = () => {
    setDataUser([
      ...dataUser,
      {
        firstname: "",
        lastname: "",
        useremail: "",
        workphone: "",
        mobilephone: "",
      },
    ]);
  };

  const onChange = (e, index) => {
    const updateUser = dataUser.map((user, i) =>
      index === i
        ? Object.assign(user, { [e.target.name]: e.target.value })
        : user
    );
    setDataUser(updateUser);
  };

  const removeUser = (index) => {
    const filterUser = [...dataUser];
    filterUser.splice(index, 1);
    setDataUser(filterUser);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: VendorSchema,
    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("dataUser", JSON.stringify(dataUser));
        data.append("userid", userid);
        data.append("companycode", padNumber(companycode, 5));
        console.log(JSON.stringify(values))
        const response = await axios.post("/addclients", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200) {
          setSuccess(true);
          setError(false);
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      action.resetForm();
      setDataUser([
        {
          firstname: "",
          lastname: "",
          useremail: "",
          workphone: "",
          mobilephone: "",
        },
      ]);
    },
  });
  const renderLabel = (label, isRequired) => (
    <span>
      {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
    </span>
  );
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <Header title="Add Client" />
          <h3>Client Details</h3>
          <Box sx={{ display: 'flex', gap: 2 }}>
                        {userrole === "Superadmin" && companycode === 1 ? (
                            <FormControl
                                fullWidth
              
                                sx={{ gridColumn: "span 2" }}
                            >
                                <CompanycodeSearch onSearchItem={onSearchItems} defaultCompanyCode={padNumber(companycode, 5)} />
                            </FormControl>
                        ) : (
                            <TextField
                                fullWidth
              
                                type="text"
                                label="Company Code"
                                name="companyCodes"
                                id="companyCodes"
                                value={padNumber(companycode, 5)}
                                sx={{ gridColumn: "span 2" }}
                            />
                        )}
                    </Box><br/>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          />
          {success && (
            <Snackbar
              open={success}
              autoHideDuration={6000}
              message="Form submitted successfully!"
            />
          )}
          {error && (
            <Snackbar
              open={error}
              autoHideDuration={6000}
              message="Error submitting form. Please try again later."
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={renderLabel("Company Name",true)}
                name="companyname"
                value={values.companyname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyname && Boolean(errors.companyname)}
                helperText={touched.companyname && errors.companyname}
                sx={{ gridColumn: "span 2", mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={renderLabel("Client Name",true)}
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2", mb: 2 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={renderLabel("Client Email",true)}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2", mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={renderLabel("Client Phone",true)}
                name="phoneno"
                value={values.phoneno}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phoneno && Boolean(errors.phoneno)}
                helperText={touched.phoneno && errors.phoneno}
                sx={{ gridColumn: "span 2", mb: 2 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="GST Number"
                name="gstnumber"
                value={values.gstnumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.gstnumber && Boolean(errors.gstnumber)}
                helperText={touched.gstnumber && errors.gstnumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Primary Contact Person"
                name="primarycontact"
                value={values.primarycontact}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.primarycontact && Boolean(errors.primarycontact)}
                helperText={touched.primarycontact && errors.primarycontact}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2} justifyContent="start">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowTab(1)}
                  className={showTab === 1 ? "active" : ""}
                  fullWidth
                >
                  Address
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowTab(3)}
                  className={showTab === 3 ? "active" : ""}
                  fullWidth
                >
                  Contact Persons
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowTab(6)}
                  className={showTab === 6 ? "active" : ""}
                  fullWidth
                >
                  Additional Information
                </Button>
              </Grid> */}
            </Grid>
          </Box>

          {showTab === 1 && (
            <Box mt={2}>
              <h3>Address of Client</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
  
                    label="Attention"
                    name="attention"
                    value={values.attention}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
  
                    label="Country"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
  
                    label={renderLabel("Client Address",true)}
                    name="address"
                    multiline
                    rows={3}
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
  
                    label={renderLabel("City",true)}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
  
                    label={renderLabel("State",true)}
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
  
                    label={renderLabel("Zip Code",true)}
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.zipcode && Boolean(errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
  
                    label="Phone Number"
                    name="phonenos"
                    value={values.phonenos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phonenos && Boolean(errors.phonenos)}
                    helperText={touched.phonenos && errors.phonenos}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {showTab === 3 && (
            <Box mt={2}>
              <Typography variant="h6">Contact Persons</Typography>
              <table>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th>Work Phone</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  {dataUser.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <TextField
                          fullWidth
        
                          name="firstname"
                          value={user.firstname}
                          onChange={(e) => onChange(e, index)}
                        />
                      </td>
                      <td>
                        <TextField
                          fullWidth
        
                          name="lastname"
                          value={user.lastname}
                          onChange={(e) => onChange(e, index)}
                        />
                      </td>
                      <td>
                        <TextField
                          fullWidth
        
                          name="useremail"
                          value={user.useremail}
                          onChange={(e) => onChange(e, index)}
                        />
                      </td>
                      <td>
                        <TextField
                          fullWidth
        
                          name="workphone"
                          value={user.workphone}
                          onChange={(e) => onChange(e, index)}
                        />
                      </td>
                      <td>
                        <TextField
                          fullWidth
        
                          name="mobilephone"
                          value={user.mobilephone}
                          onChange={(e) => onChange(e, index)}
                        />
                      </td>
                      {/* <td>
                        <IoTrashOutline onClick={() => removeUser(index)} />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button onClick={addUser}>Add Contact Person</Button>
            </Box>
          )}

          {/* {showTab === 6 && (
            <Box mt={2}>
              <Typography variant="h6">Additional Information</Typography>
              <TextField
                fullWidth
                label="Additional Information"
                name="remarks"
                multiline
                rows={3}
                value={values.remarks}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.remarks && Boolean(errors.remarks)}
                helperText={touched.remarks && errors.remarks}
              />
            </Box>
          )} */}
          <Box mt={2}>
            <Grid container spacing={2} justifyContent="start">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  type="reset"
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
          <br/>
        </Box>
      </form>
    </>
  );
};

export default AddClient;
