import React, { useState, useEffect } from "react";
import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button ,TableCell} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import axios from "../../../utils/axios"; 
import { useUser } from "../../../contexts/auth";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import Header from "../../../assets/charts/Header";
const MyLeaveDetailsList = () => {
  const { id,userid, token } = useUser();
  const [datas, setDatas] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteLeaveId, setDeleteLeaveId] = useState(null);

  const loadLeaves = async () => {
    try {
      const response = await axios.get(`/MyLeaveDetailsList/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDatas(response.data);
    } catch (error) {
      console.error("Error loading leaves:", error);
    }
  };

  useEffect(() => {
    loadLeaves();
  }, [id, token]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 const handleDelete = (req) => {
    console.log(req)
    const confirmBox = window.confirm("Do you want to withdraw applied leave?");
    if (confirmBox) {
      axios
        .post("/leavedelete", {
          requi: id, // Ensure userid is defined
          lvcount: req.leavecount,
          lvid: req.leaveid,
          empleavehistoryid: req.empleavehistoryid,
        })
        .then(() => {
          loadLeaves();
        })
        .catch((error) => {
          console.error("Error deleting leave:", error);
          alert("Error in the Code");
        });
    }
    setOpenDialog(false);
  };
  
  const columns = [
    { field: "leavetype", headerName: "Leave Type", flex: 0.2 },
    { field: "leavefrom", headerName: "Leave From", flex: 0.2 },
    { field: "leaveto", headerName: "Leave To", flex: 0.2 },
    { field: "description", headerName: "Description", flex: 0.2 },
    {
      field: "leavestage",
      headerName: "Leave Stage",
      flex: 0.2,
      renderCell: (params) => (
        <TableCell style={{ color: getColorForLeaveStage(params.value) }}>
          {params.value}
        </TableCell>
      ),
    },
    { field: "status", headerName: "Status", flex: 0.2 },
    {
      headerName: "Actions",
      flex: 0.2,
      renderCell: (params) => (
        <TableCell>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setDeleteLeaveId(params.row);
              setOpenDialog(true);
            }}
          >
            Withdraw
          </Button>
        </TableCell>
      ),
    },
  ];

  const getColorForLeaveStage = (stage) => {
    switch (stage) {
      case "Approved":
        return "#009700";
      case "Rejected":
        return "red";
      case "Pending Approval":
        return "blue";
      default:
        return "inherit";
    }
  };

  const records = datas.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(datas.length / itemsPerPage);

  return (
    <Box m="10px"> 
      <Helmet>
        <title>BBS ERP - My Leave Details</title>
      </Helmet>
      <Header title= "My Leave List"/>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      <DataGrid
        rows={records}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        getRowId={(row) => row.empleavehistoryid}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        slots={{ toolbar: GridToolbar }}
      />
   </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to withdraw this applied leave?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(deleteLeaveId)} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
  
  );
};

export default MyLeaveDetailsList;
